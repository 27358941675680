import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ComicService } from 'src/app/services/comic.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { NewComicService } from 'src/app/new-comic.service';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Subscription } from 'rxjs';
import { isAnonymousUser } from 'src/app/utilities/common.util';
import * as firebase from 'firebase';
import { signInPhoneURL, signUpURL, TINYVIEW_SHOP } from 'src/app/constants/common.constants';
import { StoryFeedService } from 'src/app/services/story-feed.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss', './../../pages/comic-wrapper/comic-wrapper.component.scss'],
})
export class SidebarComponent implements OnInit {
  public userInfo = {
    img: '',
    name: '',
    phone: '',
    subscription: '',
    formattedEmailName: ''
  }
  public hasLatestComic: boolean;
  public isCreatorDropdownOpen: boolean;
  public currentUrl: string;
  public manageSubscriptionObj: any;
  public activeIcon: string;
  public modalRef: BsModalRef;
  public modalLinkRef: BsModalRef;
  public isAnalysts: boolean = false;
  public admins: any = [];
  public comicTitle: any = [];
  public tinyviewAdmin: boolean = false;
  public isAdmin: boolean;
  public canEdit: boolean = true;
  public canAdd: boolean = this.comicService.getCurrentUrl() != '/' && this.comicService.getCurrentUrl() != '/tinyview/comic-series-directory' ? true : false;
  public urlSubscription: Subscription
  public isCover: any;
  public scheduleAlertsURL: string;


  private IMAGE_END_POINT = `${environment.IMAGE_END_POINT}`

  public noImageUser: string = '../../../assets/images/male-user-100.png';
  iconUrls: { [key: string]: string } = {
    directory: '../../../assets/images/new-icons/signpost-100.png',
    tickbox: '../../../assets/images/new-icons/tick-box-100.png',
    home: '../../../assets/images/new-icons/home-100.png',
    user: '../../../assets/images/new-icons/user-menu-male-100.png',
    legal: '../../../assets/images/new-icons/icons8-goodnotes-100.png',
    terms: '../../../assets/images/new-icons/icons8-terms-and-conditions-100.png',
    subscribe: '../../../assets/images/new-icons/icons8-renew-100.png',
    policy: '../../../assets/images/new-icons/icons8-privacy-policy-100.png',
    manageSubscription: '../../../assets/images/new-icons/icons8-cancel-subscription-100.png',
    influence: '../../../assets/images/new-icons/tinyview-influence-points-outline-100-444444.png',
    contactUs: '../../../assets/images/new-icons/icons8-mailing-100.png',
    creator: '../../../assets/images/new-icons/icons8-paint-palette-50.png',
    addComic: '../../../assets/images/new-icons/icons8-add-50.png',
    editComic: '../../../assets/images/new-icons/icons8-edit-50.png',
    addLink: '../../../assets/images/new-icons/icons8-add-link-50.png',
    scheduleAlert: '../../../assets/images/new-icons/icons8-add-reminder-50.png',
    dashboard: '../../../assets/images/new-icons/icons8-combo-chart-50.png',
    arrow: '../../../assets/images/new-icons/icons8-expand-arrow-100.png',
    back: '../../../assets/images/new-icons/back-arrow-100.png',
    friends: '../../../assets/images/new-icons/icons8-user-account-100-2.png',
    inviteFriends: '../../../assets/images/new-icons/icons8-add-user-group-man-man-100.png',
    friendReq: '../../../assets/images/new-icons/alarm-100.png',
    editProfile: '../../../assets/images/new-icons/icons8-registration-100.png',
    referrals: '../../../assets/images/new-icons/icons8-payroll-100.png',
    manageAlerts: '../../../assets/images/new-icons/alarm-100.png',
    settings: '../../../assets/images/new-icons/icons8-settings-100.png',
    shop: '../../../assets/images/new-icons/icons8-shopping-bag-100-2.png',
  };
  iconUrlsDark: { [key: string]: string } = {
    directory: '../../../assets/images/new-icons/signpost-100-2.png',
    tickbox: '../../../assets/images/new-icons/tick-box-100-2.png',
    home: '../../../assets/images/new-icons/home-100-2.png',
    user: '../../../assets/images/new-icons/user-menu-male-100-2.png',
    legal: '../../../assets/images/new-icons/icons8-goodnotes-100-2.png',
    terms: '../../../assets/images/new-icons/icons8-terms-and-conditions-100-2.png',
    subscribe: '../../../assets/images/new-icons/icons8-renew-100-2.png',
    policy: '../../../assets/images/new-icons/icons8-privacy-policy-100-2.png',
    influence: '../../../assets/images/new-icons/tinyview-influence-points-filled-100-444444.png',
    contactUs: '../../../assets/images/new-icons/icons8-mailing-100-2.png',
    creator: '../../../assets/images/new-icons/icons8-paint-palette-50-2.png',
    addComic: '../../../assets/images/new-icons/icons8-add-50-2.png',
    editComic: '../../../assets/images/new-icons/icons8-edit-50-2.png',
    addLink: '../../../assets/images/new-icons/icons8-add-link-50-2.png',
    scheduleAlert: '../../../assets/images/new-icons/icons8-add-reminder-50-2.png',
    dashboard: '../../../assets/images/new-icons/icons8-combo-chart-50-2.png',
    arrow: '../../../assets/images/new-icons/icons8-collapse-arrow-100.png',
    friends: '../../../assets/images/new-icons/icons8-user-account-100.png',
    friendReq: '../../../assets/images/new-icons/alarm-100-2.png',
    editProfile: '../../../assets/images/new-icons/icons8-registration-100-filled.png',
    referrals: '../../../assets/images/new-icons/icons8-payroll-100-2.png',
    manageAlerts: '../../../assets/images/new-icons/alarm-100-2.png',
  };
  public islastClickHome: boolean = true;
  public isUserDropdownOpen: boolean = false;
  public isLegalDropdownOpen: boolean = false;
  public UID: string;
  public isContactUsClicked: boolean;
  public isLoadingUser: boolean;
  isSubscribed: boolean;
  public isAnonymousUser: boolean = true
  public isFriendsDropdownOpen: boolean;
  public inviteFriendsRef: BsModalRef;
  public isMobile: boolean;
  public isIncompleteProfile: boolean;
  public previousItem: string;
  public showMenu: boolean = false;
  seriesName: any;
  isSeriesCreator: boolean;
  isSettingsDropdownOpen: boolean;
  showEditComic: boolean;
  isSeriesPage: boolean;
  isComicPage: boolean;
  isStoryPage: boolean;
  isSubscribePage: boolean;
  influencePointsPage: boolean;
  subDetails: any;

  constructor(
    private router: Router,
    private comicService: ComicService,
    private feedService: StoryFeedService,
    private newComicService: NewComicService,
    private location: Location,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private modalService: BsModalService,
    @Inject(PLATFORM_ID) private platform: object,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.isAnonymousUser = isAnonymousUser();
    this.isLoadingUser = true;
    this.currentUrl = this.comicService.getCurrentUrl();
    this.isMobile = this.newComicService.isMobileView();
    this.isComicPage = this.comicService.isComicPage();
    this.isSubscribePage = this.newComicService.isSubscribePage();
    this.influencePointsPage = this.newComicService.isInfluencePointsPage();
    this.isStoryPage = this.newComicService.isStoryPage();
    this.showEditComic = this.isComicPage && !this.isSubscribePage && !this.influencePointsPage;
    this.isSeriesPage = this.comicService.isSeriesPage();
    this.scheduleAlertsURL = this.isStoryPage ? this.localStorageService.getItem('scheduleAlertsURL') : this.currentUrl;
    this.activeIcon =
      (this.location.path() === '' &&
        this.localStorageService.getItem('selectedMenuItem') === 'following')
        ? '/following'
        : this.location.path();
    this.getUserDetails();
    this.newComicService.getsidebarItemSelected().subscribe((val) => {
      if (val && !val.sameItemClicked) {
        if (val && val.menuItem === 'home') {
          this.islastClickHome = true;
          this.activeIcon = '';
          this.localStorageService.setItem('selectedMenuItem', 'home');
          this.localStorageService.setItem('isComingFromFollowing', false);
        } else if (val && val.menuItem === 'following') {
          this.islastClickHome = false;
          this.activeIcon = '/following';
          this.localStorageService.setItem('selectedMenuItem', 'following');
          this.localStorageService.setItem('isComingFromFollowing', true);
        }
      }
    }, err => {
      this.activeIcon = '';
    })
    const user = firebase.auth().currentUser;
    this.UID = user ? user.uid : null;
    this.isCover = this.comicService.isCoverPage();
    if (this.comicService.isStoryPage()) {
      const urlArr = this.currentUrl.split('/');
      this.feedService.getStoryByID(urlArr[urlArr.length - 1]).then(res => {
        this.seriesName = res.data.data.series;
        this.isUserPermitted();
      });
    } else {
      this.isUserPermitted();
    }
    this.urlSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // TODO: Check the user access here.
        this.canAdd = this.comicService.getCurrentUrl() != '/' && this.comicService.getCurrentUrl() != '/tinyview/comic-series-directory' ? true : false;
      }
    });
    // checks
    ['/tinyview/subscribe', '/edit-profile'].includes(this.activeIcon) ? this.isUserDropdownOpen = true : null;
    ['/terms-conditions', '/privacy-policy'].includes(this.activeIcon) ? this.isLegalDropdownOpen = true : null;
    ['/manage-alerts'].includes(this.activeIcon) ? this.isSettingsDropdownOpen = true : null;
    ['/see-friends', '/friend-request'].includes(this.activeIcon) ? this.isFriendsDropdownOpen = true : null;

    // refresh login data on successfu signin
    this.newComicService.getRefreshLoginDataEvent().subscribe(() => {
      this.getUserDetails();
    });

    // code to handle same menu click for home and following page for smart scrolling purpose
    if (!this.previousItem) {
      if (['', '/'].includes(this.activeIcon)) {
        this.previousItem = 'home';
      }
      if (this.activeIcon === '/following') {
        this.previousItem = 'tickbox';
      }
      if (this.activeIcon === 'directory') {
        this.previousItem = 'directory';
      }
    }

    this.feedService.loadComicAction().subscribe((scheduleAlertsURL) => {
      if (scheduleAlertsURL) {
        this.scheduleAlertsURL = scheduleAlertsURL;
      }
    });
  }

  ngAfterViewInit() {
    this.showMenu = true;
  }

  public getUserDetails() {
    this.comicService.getUserDetails().then(val => {
      this.isAnonymousUser = isAnonymousUser();
      this.isIncompleteProfile = !this.isAnonymousUser && (!val.data.displayName || !val.data.gender);
      this.setUserDetails(val.data);
      this.isLoadingUser = false;
    });
  }

  public goBack(): void {
    if ((window.history.length <= 2) || this.route.snapshot.queryParams.showSubscriptionMsg) {
      // If history length is 2 or less, navigate to home page
      this.router.navigate(['/']);
    } else {
      // Otherwise, go back
      this.location.back();
    }
  }

  public setUserDetails(data) {
    this.userInfo.name = data.displayName;
    this.userInfo.img = data.photoURL;
    // Check the formatted name
    this.userInfo.formattedEmailName = data.email;
    this.userInfo.phone = data.phoneNumber;
    if (data.badges && data.badges.length) {
      this.userInfo.subscription = `../../${this.comicService.mapUserBadge(data.badges[0])}`
    }
    this.subDetails = data.subscriptions.length && data.subscriptions[0];
    this.isSubscribed = this.subDetails ? true : false;
  }

  public isUserPermitted() {
    if (isPlatformBrowser(this.platform)) {
      this.comicService.getComicTitle().subscribe(res => {
        for (var key in res) {
          if (res[key].roles) {
            res[key].roles.map((i) => {
              if (i.admins && i) {
                i.admins.map((admin) => {
                  this.admins.push({ 'admin': admin, 'title': key })
                })
              }
            });
          }
        }
        if (this.admins.length > 0) {
          const user = firebase.auth().currentUser;
          for (let value of res.tinyview.roles) {
            if (value.analysts && value.analysts.length > 0) {
              for (let item = 0; item < value.analysts.length; item++) {
                if (user) {
                  if (user.phoneNumber == value.analysts[item]) {
                    this.isAnalysts = true;
                    this.localStorageService.setItem('isTinyviewAnalyst', JSON.stringify(this.isAnalysts));
                    break;
                  }
                }
              };
            }
          }
          for (var data = 0; data < this.admins.length; data++) {
            if (user && this.admins[data]) {
              if (user.phoneNumber == this.admins[data].admin) {
                this.comicTitle.push(this.admins[data].title)
                if (this.admins[data].title !== 'tinyview') {
                  this.isSeriesCreator = true;
                  this.localStorageService.setItem('isSeriesCreator', JSON.stringify(this.isSeriesCreator));
                  if (this.isAdmin) continue;
                  if ((location.pathname.split('/').slice(0, 2).join('/').replace(/\//g, '') == this.admins[data].title || location.pathname.replace(/\//g, '') == this.admins[data].title || (this.seriesName == this.admins[data].title))) {
                    this.isAdmin = true;
                    this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
                  } else {
                    this.isAdmin = false;
                    this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
                  }
                } else {
                  this.tinyviewAdmin = true;
                  this.isAdmin = true;
                  this.localStorageService.setItem('tinyviewAdmin', JSON.stringify(this.tinyviewAdmin));
                  this.localStorageService.setItem('isAdmin', JSON.stringify(this.isAdmin));
                }
              }
            }
          }
        }
      });
    }
  }

  public navigateTo(icon: string, event?): void {
    event && event.preventDefault(); // to stop it from reloading the page as anchor tag does this sometimes
    // code to handle same menu click for home and following page for smart scrolling purpose
    const sameItemClicked = this.previousItem === icon;
    // Array must include icons, those having sub menu
    if (sameItemClicked && !['tickbox', 'home', 'directory', 'settings', 'user', 'creator', 'legal', 'friends', 'shop'].includes(icon)) {
      return;
    }
    if (!['shop'].includes(icon)) {
      this.activeIcon = icon;
    }
    // For navigation
    switch (icon) {
      case 'directory':
        this.newComicService.setsidebarItemSelected({ menuItem: 'directory', sameItemClicked: sameItemClicked });
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.router.navigate(['/tinyview/comic-series-directory']);
        break;
      case 'tickbox':
        this.newComicService.setsidebarItemSelected({ menuItem: 'following', sameItemClicked: sameItemClicked });
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.router.navigate(['']);
        break;
      case 'home':
        this.newComicService.setsidebarItemSelected({ menuItem: 'home', sameItemClicked: sameItemClicked });
        if (sameItemClicked) return; // stopping navigation if same home is clicked
        this.router.navigate(['']);
        break;
      case 'influence':
        this.router.navigate(['tinyview/influence-points']);
        break;
      case 'see-friends':
        this.router.navigate(['see-friends']);
        break;
      case 'friend-request':
        this.router.navigate(['friend-request']);
        break;
      case 'user':
        // this.isUserDropdownOpen = false;
        this.isLegalDropdownOpen = false;
        this.isCreatorDropdownOpen = false;
        this.isFriendsDropdownOpen = false;
        this.isSettingsDropdownOpen = false;
        this.isUserDropdownOpen = !this.isUserDropdownOpen;
        break;
      case 'creator':
        this.isUserDropdownOpen = false;
        this.isLegalDropdownOpen = false;
        this.isFriendsDropdownOpen = false;
        // this.isCreatorDropdownOpen = false;
        this.isSettingsDropdownOpen = false;
        this.isCreatorDropdownOpen = !this.isCreatorDropdownOpen;
        break;
      case 'subscribe':
        this.router.navigate(['/subscribe']);
        break;
      case 'manage-subscription':
        this.manageSubscription()
        break;
      case 'legal':
        this.isUserDropdownOpen = false;
        // this.isLegalDropdownOpen = false;
        this.isCreatorDropdownOpen = false;
        this.isFriendsDropdownOpen = false;
        this.isSettingsDropdownOpen = false;
        this.isLegalDropdownOpen = !this.isLegalDropdownOpen;
        break;
      case 'friends':
        this.isUserDropdownOpen = false;
        this.isLegalDropdownOpen = false;
        this.isCreatorDropdownOpen = false;
        this.isSettingsDropdownOpen = false;
        this.isFriendsDropdownOpen = !this.isFriendsDropdownOpen;
        break;
      case 'settings':
        this.isUserDropdownOpen = false;
        this.isLegalDropdownOpen = false;
        this.isCreatorDropdownOpen = false;
        this.isFriendsDropdownOpen = false;
        this.isSettingsDropdownOpen = !this.isSettingsDropdownOpen;
        break;
      case '/privacy-policy':
        this.router.navigate(['/privacy-policy']);
        break;
      case '/terms-conditions':
        this.router.navigate(['/terms-conditions']);
        break;
      case 'logout':
        if (!this.isAnonymousUser) {
          this.signOut();
        }
        break;
      case 'edit-profile':
        this.router.navigate(['/edit-profile']);
        break;
      case 'shop':
        window.open(TINYVIEW_SHOP, '_blank');
        break;
      default:
        this.newComicService.setsidebarItemSelected('home');
        this.router.navigate(['']);
        break;
    }

    this.previousItem = icon;
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'text-app-modal',
      ignoreBackdropClick: true
    }));
  }

  public openLinkModal(template: TemplateRef<any>) {
    this.modalLinkRef = this.modalService.show(template, Object.assign({}, {
      class: 'text-app-modal',
      ignoreBackdropClick: true
    }));
  }

  async onEdit(newComic: boolean = false, showModal) {
    let comic = this.comicService.getCurrentUrl();
    if (newComic) {
      this.comicService.getComicChapters(this.currentUrl, '')
        .subscribe(async (resp) => {
          resp.comics.panels.map(data => {
            if (data.title == 'Latest Comics') {
              this.hasLatestComic = true;
              if (comic != '/tinyview/comic-series-directory') {
                this.router.navigate(['/edit', { comic: comic }], { queryParams: { new_comic: newComic } });
              }
            }
          });
          if (!this.hasLatestComic) {
            this.hasLatestComic = false;
            this.openModal(showModal)
          }
        })
    } else {
      if (comic != '/tinyview/comic-series-directory') {
        this.router.navigate(['/edit', { comic: comic }], { queryParams: { new_comic: newComic } });
      }
    }
  }

  public getIconUrl(icon: string): string {
    switch (icon) {
      case 'directory':
      case '/tinyview/comic-series-directory':
        return ([icon, '/tinyview/comic-series-directory'].includes(this.activeIcon)) ? this.iconUrlsDark['directory'] : this.iconUrls['directory']
        break;
      case '/following':
        return this.activeIcon === icon ? this.iconUrlsDark['tickbox'] : this.iconUrls['tickbox']
        break;
      case 'user':
        return this.iconUrls['user']
        break;
      case 'back':
        return this.iconUrls['back']
        break;
      case 'legal':
        return this.iconUrls['legal']
        break;
      case 'creator':
        return this.iconUrls['creator']
        break;
      case 'friends':
        return this.iconUrls['friends']
        break;
      case 'invite-friends':
        return this.iconUrls['inviteFriends']
        break;
      case 'contact-us':
        return this.isContactUsClicked ? this.iconUrlsDark['contactUs'] : this.iconUrls['contactUs']
        break;
      case '/terms-conditions':
        return this.activeIcon === icon ? this.iconUrlsDark['terms'] : this.iconUrls['terms']
        break;
      case '/privacy-policy':
        return this.activeIcon === icon ? this.iconUrlsDark['policy'] : this.iconUrls['policy']
        break;
      case '/see-friends':
        return this.activeIcon === icon ? this.iconUrlsDark['friends'] : this.iconUrls['friends']
        break;
      case '/friend-request':
        return this.activeIcon === icon ? this.iconUrlsDark['friendReq'] : this.iconUrls['friendReq']
        break;
      case '/subscribe':
        return this.activeIcon === '/tinyview/subscribe' ? this.iconUrlsDark['subscribe'] : this.iconUrls['subscribe']
        break;
      case '/add-comics':
        return this.activeIcon === '/edit' ? this.iconUrlsDark['addComic'] : this.iconUrls['addComic']
        break;
      case '/add-link':
        return this.activeIcon === '/edit' ? this.iconUrlsDark['addLink'] : this.iconUrls['addLink']
        break;
      case '/edit':
        return this.activeIcon === icon ? this.iconUrlsDark['editComic'] : this.iconUrls['editComic']
        break;
      case '/schedule-alerts':
        return this.activeIcon === '/edit' ? this.iconUrlsDark['scheduleAlert'] : this.iconUrls['scheduleAlert']
        break;
      case '/dashboard':
        return this.activeIcon === '/edit' ? this.iconUrlsDark['dashboard'] : this.iconUrls['dashboard']
        break;
      case 'manage-subscription':
        return this.iconUrls['manageSubscription']
        break;
      case '/tinyview/influence-points':
        return this.activeIcon === icon ? this.iconUrlsDark['influence'] : this.iconUrls['influence']
        break;
      case '/edit-profile':
        return this.activeIcon === icon ? this.iconUrlsDark['editProfile'] : this.iconUrls['editProfile'];
        break;
      case '/referrals':
        return this.activeIcon === icon ? this.iconUrlsDark['referrals'] : this.iconUrls['referrals'];
        break;
      case 'settings':
        return this.iconUrls['settings'];
        break;
      case '/manage-alerts':
        return this.activeIcon === icon ? this.iconUrlsDark['manageAlerts'] : this.iconUrls['manageAlerts'];
        break;
      case 'shop':
        return this.iconUrls['shop'];
        break;
      default:
        return this.activeIcon === '' ? this.iconUrlsDark['home'] : this.iconUrls['home']
    }
  }

  public async signOut() {
    this.userInfo.phone = null;
    this.comicService.clearUserData();
    await this.authService.signOut();
  }

  public async manageSubscription() {
    switch (this.subDetails && this.subDetails.store.toLowerCase()) {
      case 'stripe':
        try {
          const url = await this.comicService.createPortalLink();
          if (url) {
            this.manageSubscriptionObj = { url: url, target: "_blank" };
          }
        } catch (error) {
          console.log(error);  
        }
        break;
      case 'apple':
      case 'ios':
        this.manageSubscriptionObj = { url: 'https://apps.apple.com/account/subscriptions', target: '_blank' };
        break;
      case 'android':
      case 'google':
        this.manageSubscriptionObj = { url: 'https://play.google.com/store/account/subscriptions', target: '_blank' };
        break;
      default:
        this.manageSubscriptionObj = { url: 'tinyview/subscribe', target: '_self', internalRedirection: true };
        this.isSubscribed = false;
        break;
    }
    if (this.manageSubscriptionObj && this.manageSubscriptionObj.url && this.manageSubscriptionObj.target) {
      if (this.manageSubscriptionObj.internalRedirection) {
        this.router.navigate([this.manageSubscriptionObj.url]);
        return;
      }
      window.open(this.manageSubscriptionObj.url, this.manageSubscriptionObj.target);
    }
  }

  public getDropdownIcon(type) {
    switch (type) {
      case 'user':
        return this.isUserDropdownOpen === true ? this.iconUrlsDark['arrow'] : this.iconUrls['arrow'];
      case 'legal':
        return this.isLegalDropdownOpen === true ? this.iconUrlsDark['arrow'] : this.iconUrls['arrow'];
      case 'creator':
        return this.isCreatorDropdownOpen === true ? this.iconUrlsDark['arrow'] : this.iconUrls['arrow'];
      case 'friends':
        return this.isFriendsDropdownOpen === true ? this.iconUrlsDark['arrow'] : this.iconUrls['arrow'];
      case 'settings':
        return this.isSettingsDropdownOpen === true ? this.iconUrlsDark['arrow'] : this.iconUrls['arrow'];
      default:
        // Handle the default case here, if needed
        break;
    }
  }

  public ngOnDestroy() {
    this.urlSubscription && this.urlSubscription.unsubscribe();
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymousUser ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  public getRedirectionAfterSignin(activeIcon) {
    const queryParams = {
      comic: ''
    };
    activeIcon = (activeIcon && activeIcon.indexOf('?') > -1) ? activeIcon.split('?')[0] : activeIcon;
    if (activeIcon.indexOf('signin') > -1) {
      activeIcon = '';
    }
    switch (activeIcon) {
      case '/following':
        queryParams['comic'] = '';
        break;
      default:
        queryParams['comic'] = activeIcon;
        break;
    }

    this.router.navigate([signInPhoneURL], { queryParams });
  }

  public signUp() {
    const queryParams = {
      comic: ''
    };

    let activeIcon = this.currentUrl;

    activeIcon = (activeIcon && activeIcon.indexOf('?') > -1) ? activeIcon.split('?')[0] : activeIcon;
    switch (activeIcon) {
      case '/following':
        queryParams['comic'] = '';
        break;
      default:
        queryParams['comic'] = activeIcon;
        break;
    }

    this.router.navigate([signUpURL], { queryParams });
  }
}
