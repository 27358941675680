import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, TemplateRef, HostListener, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { ComicService } from '../services/comic.service';
import { Store } from '@ngrx/store';
import { Post } from '../redux/model';
import * as PostActions from '../redux/action';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe, DOCUMENT, isPlatformBrowser, PlatformLocation } from '@angular/common';
import * as firebase from 'firebase';
import 'firebase/functions';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { HeaderComponent } from '../header/header.component';
import { StoryFeedService } from '../services/story-feed.service';
import { PanelService } from '../services/panel/panel.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { subscribeURL, upgradeTitle, latestComics, templateTypes, traceStates, BROWSER, carouselType, FBQ_TRACKING_EVENTS, AUTH_TYPE, signInPhoneURL, signUpURL, allSeriesURL, API_KEYS } from '../constants/common.constants';
import * as util from '../utilities/common.util';
import { ToastrService } from 'ngx-toastr';
import { INFLUENCE_PAGE } from '../constants/influence.constants';
import { LocalStorageService } from '../services/local-storage.service';
import { NewComicService } from '../new-comic.service';
import { FbqService } from '../services/fbq.service';
import { UserService } from '../services/user.service';
import { SessionStorageService } from '../services/session-storage.service';
import { FollowModalComponent } from '../components/follow-modal/follow-modal.component';
import { AppService } from '../services/app.service';
import { ApiService } from '../services/api.service';
import { DOCUMENT_TYPE, PageMetaService } from '../services/page-meta.service';

const COMIC_IMG_API = `${environment.API_END_POINT}`;
let _self = null;
interface AppState {
  post: Post;
}

@Component({
  selector: 'app-comic',
  templateUrl: './comic.component.html',
  styleUrls: ['./comic.component.scss', '../payments/payments.component.scss'],
})
export class ComicComponent implements OnInit, OnDestroy {
  // @ViewChild('appModal', { static: true }) appModal: TemplateRef<any>;
  @ViewChild('shareModal', { static: false }) shareModal: TemplateRef<any>;
  @ViewChild(HeaderComponent, { static: false }) chviewChild: HeaderComponent;
  comicComments: string;
  record: number;
  isOpenApp = false;
  openAppModalRef: BsModalRef;
  chapterList: any;
  navChapterList: any;
  actionUrlArray: any = [];
  feedsSubscription: Subscription;
  errorMsg: string = null;
  isPaid: boolean;
  fetchDone: boolean;
  imgPath: string = '';
  hideOpenInApp: boolean;
  currentDevice: string;
  resp: any;
  comicType: string;
  pageInfo: any;
  navUrl: string;
  fromPanelClick: boolean = false;
  fromComicNavClick: boolean = false;
  previousUrl: string;
  homeRoute: string;
  urlSubscribe: Subscription;
  currentUrl: string;
  webUrl: string;
  isLiked: boolean;
  isSubscribed: boolean = false;
  canEdit: boolean = true;
  userLikesArray: any;
  errFetchingLikesData: boolean;
  metaDescription: any;
  isCover: string | boolean;
  followBtnText: string;
  isIpad: boolean;
  showSendMeAppModal: boolean;
  modalRef: BsModalRef;
  myform: FormGroup;
  smsResponse: string;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  userFollowedComics: any;
  scrollValue: number;
  seriesTitle: string;
  seriesImage: string;
  seriesHomeNavigation: string;
  clickBack: boolean = false;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  newChapterList: any;
  footerArray: any[];
  headerArray: any[];
  comicDate: any;
  noOfComic: number = 20;
  comicCount: boolean = false;
  totalComics: number;
  prevURL: any;
  panelPublishDate: boolean = true;
  likeCount: any;
  previewPanelCount: boolean;
  showAllComics: boolean = true;
  isHomePage: boolean;
  counts: any = {};
  previousArray: any = {};
  cacheData: any = {};
  previewPanels: any;
  willShowLoader: boolean;
  isVisitComic: boolean = false;
  // userVisit: string;
  store_ID: boolean;
  ogImage: string;
  admin: any;
  private readonly $userDetails: Subject<any>;
  hasApp: any = true;
  actionForFooter: any;
  comicIndex: number;
  test: any;
  followedComics: any = [];
  action: any;
  storyIds: any = [];
  allStoryLikes: any;
  shareData = {
    title: 'Tinyview',
    text: null,
    url: null,
  };
  showShare: boolean = false;
  repostCount: any;
  commentCount: any;
  storyID: string;
  isLike: any;
  actionModalRef: BsModalRef;
  anonymousUser: any;
  likedComic: any;
  refId: string;
  cachedChapterList: any = [];
  isFollowComic: boolean = false;
  previousAction: any;
  prodID: any;
  showLoader: boolean = false;
  seriesComicData: any;
  seriesName: any;
  param: string;
  viewCount: any;
  storyPage: boolean;
  refType: any;
  giftsCounts: any;
  showFeedStory: boolean = false;
  continueReading: any = null;
  lastEpisode: any = {};
  isLiked2: boolean;
  Url: string;
  productIds: any = [];
  showUpgradeTitle: string = '';
  subscribeURL = subscribeURL;
  public isUserLikesLoading: boolean;
  public isComicPage: boolean;
  public isSubscribePage: boolean;
  public isNewUserPage: boolean;
  public latestComics = latestComics;
  public templateTypes = templateTypes;
  public isMobileView: boolean;
  public isLoadingHeader: boolean;
  alternateNumber: { countryCode: string; phone: string; };
  actionForStory: any;
  giftedItems: any;
  showInfluencePanel: boolean;
  influencePoints: any;
  isComicUnlocking: boolean;
  isBonusPanelComic: boolean;
  actionType: any;
  seriesForNavigation: string;
  extActionURL: string;

  // For sharing
  sharingStoryAction: any;
  sharingStoryID: any;
  isDirectoryPage: boolean;
  isSeriesPage: boolean;
  shareLink: any;
  shareRef: BsModalRef;
  isDesktop: boolean;
  isAndroid: boolean;
  shareConfig: { title: string; action: string; image: string; show: boolean; }[];
  isWindows: boolean;
  sidebarItemSelected: string;
  showNotifyForGuestUser: boolean;
  monthlyFreeComic: any;
  comicLeftToView: number;
  comicViewed: boolean;
  userAccessToComic: any;
  UID: any;
  followSeriesBanner: boolean;
  homeFeedsSubscription: Subscription;
  inviteFriendsRef: BsModalRef;
  isFromFollowing: boolean;
  seriesEpisodes = [];
  feedEpisodes = [];
  comicSeriesViewTabs = {
    all: true,
    unread: false
  }
  listIcon = '../../assets/images/new-icons/icons8-list-96.png';
  tocIcon = '../../assets/images/new-icons/icons8-news-100.png';
  sharingStoryIsWebsite: boolean;
  isSubscriber: any;
  hasAnyChannel: boolean;
  displaySeriresFollowed: string;
  isComicNavLoading: boolean;
  // series = {
  //   displayValue: '',
  //   value: ''
  // }
  currentSeriesDetails = {
    title: '',
    action: ''
  }
  tinyviewPage: boolean;
  hasBonusPanel: boolean;
  hasFollowBtn: boolean;
  showDoubleTick: any;
  ctaList: any[];
  commentModalRef: BsModalRef;
  showListViewGIF: boolean;
  subscriptions: any = {};

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.currentDevice = this.comicService.getOperatingSystem();
  }

  // TODO(Shashank): only triggers on mobile due to overflow on main-container
  @HostListener("document:scroll", ['$event'])
  onWindowScroll(event: any) {
    this.infiniteScroll(event);
  }

  // constructor
  constructor(
    private datePipe: DatePipe,
    public panelService: PanelService,
    private readonly comicService: ComicService,
    public feedService: StoryFeedService,
    public authService: AuthService,
    public readonly router: Router,
    private titleService: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platform: object,
    private store: Store<AppState>,
    private modalService: BsModalService,
    public location: PlatformLocation,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private toastr: ToastrService,
    public localStorageService: LocalStorageService,
    public sessionStorageService: SessionStorageService,
    private newComicService: NewComicService,
    private fbqService: FbqService,
    private readonly userService: UserService,
    private appService: AppService,
    private apiService: ApiService,
    private metaService: Meta,
    @Inject(DOCUMENT) private documentService: DOCUMENT_TYPE,
    private pageMetaService: PageMetaService
  ) {
    this.param = this.route.snapshot.paramMap.get('ID');
    if (isPlatformBrowser(this.platform)) {
      this.storyPage = this.newComicService.isStoryPage();
      // Get scroll position for going back to the current location.
      location.onPopState((event) => {
        setTimeout(() => {
          const scrollValue =  this.localStorageService.getItem('scroll') && this.localStorageService.getItem('scroll')[this.comicService.getCurrentUrl()]
          window.scroll(0, Number(scrollValue));
        }, 0);
      });
    }
    this.$userDetails = this.comicService.$userDetails;
    this.currentUrl = this.comicService.getCurrentUrl();
    this.chapterList = [];
    this.footerArray = [];
    this.headerArray = [];
    this.actionUrlArray = [];
    this.navChapterList = [];
    this.getComicFeedData(); // get comic data for comic pages...
    this.getComicData();
    this.getUserDetails();
    this.isFromFollowing = this.localStorageService.getItem('selectedMenuItem') && this.localStorageService.getItem('selectedMenuItem') === 'following';
    // cached values for every page.
    let cacheKey = this.currentUrl;
    this.setCacheKey(cacheKey);
    if (this.comicService.cacheComic[cacheKey]) {
      // this.chapterList = this.comicService.cacheComic[cacheKey]; TODO: need to handle caching
      if (['', '/'].includes(this.currentUrl) && !(this.localStorageService.getItem('selectedMenuItem') === 'home')) {
        this.action = this.chapterList[this.chapterList.length - 1] ? this.chapterList[this.chapterList.length - 1] && this.chapterList[this.chapterList.length - 1].myFeedID : null;
      } else if (this.comicService.isCoverPage()) {
        this.action = this.chapterList[this.chapterList.length - 1] ? this.chapterList[this.chapterList.length - 1] && this.chapterList[this.chapterList.length - 1].storyID : null;
      } else if (this.localStorageService.getItem('isSubscription') === true) {
        this.removeAction(this.chapterList, 'influencePanel');
      }
      this.fetchDone = true;
    }
    if (this.sessionStorageService.getItem('comicSeriesViewTabs')) { 
      this.setViewTypeOnSeriesPage();
    } else {
      this.setDefaultTabs();
    }
  }

  ngOnInit() {
    this.handleRefreshEvent();
    let series = [];
    if (this.storyPage) this.chapterList.length = 0;
    if (isPlatformBrowser(this.platform)) { // things to load for tracing performance
      this.isComicPage = this.comicService.isComicPage();
      this.storyPage = this.newComicService.isStoryPage();
      this.isDirectoryPage = location.pathname.includes('/tinyview/comic-series-directory');
      this.isHomePage = this.newComicService.isHomePage();
      this.isSubscribePage = this.currentUrl == subscribeURL;
      this.isNewUserPage = this.newComicService.isNewUserPage();
      this.tinyviewPage = this.comicService.isTinyviewPage(this.currentUrl);

      this.comicService.getComicTitle().subscribe(async (res) => {
        series = Object.keys(res).map(key => '/' + key);
        const pathName = window.location.pathname;
        const seriesName =  pathName.split('/')[1]
        if ([...series].includes(pathName)) {
          this.isSeriesPage = true;
          this.currentSeriesDetails['title'] = res[`${seriesName}`].title;
          this.currentSeriesDetails['action'] = res[`${seriesName}`].action;
          await this.getNavigationData(); // get json data for previous and next episodes...
          this.localStorageService.setItem('selectedMenuItem', 'series');
        } else {
          this.isSeriesPage = false;
        }
        if (this.isComicPage) {
          this.currentSeriesDetails['action'] = res[seriesName].action
          this.currentSeriesDetails['title'] = res[seriesName].title;
        }

        // for page sharing
        if (series.length && ['/tinyview/comic-series-directory', '/', ...series].includes(window.location.pathname)) {
          const pathName = window.location.pathname === '/' ? '' : window.location.pathname;
          this.feedService.getStory(pathName + '/index.json', null, null, true).then(res => {
            if (res && res.data && res.data.data) {
              this.sharingStoryID = res.data.data.storyID;
              this.sharingStoryAction = res.data.data.action;
              this.sharingStoryIsWebsite = res.data.data.actionType === 'website';
              if (!this.isSeriesPage) {
                this.currentSeriesDetails['title'] = res.data.data.title;
                this.currentSeriesDetails['action'] = res.data.data.action;
              }
            }
          })
        }
      });

      const performance = firebase.performance();
      const title = this.isComicPage ? 'comic_page'
        : this.storyPage ? 'comment_page'
          : this.isDirectoryPage ? 'directory_page'
            : this.isHomePage ? 'home_page'
              : this.isSeriesPage ? 'series_page'
                : this.isSubscribePage ? 'subscribe_page'
                  : 'other_page';

      this.comicService.pageLoadTrace = performance.trace(title);
      try {
        // [TODO]: Need to check why its breaking, on some pages
        // this.comicService.pageLoadTrace.putAttribute('url', window.location.href);
      } catch (error) {
        console.log('Unable to put url in page load tracing', error);
      }
      this.comicService.pageLoadTrace.start();

      this.isDesktop = this.newComicService.isDesktop();
      this.isAndroid = this.newComicService.isAndroid();
      this.isWindows = this.newComicService.isWindows();
      this.shareConfig = this.comicService.getShareItems(this.isDesktop, this.isAndroid, this.isWindows);

      // CHECKING IF USER IS SUBSCRIBED OR NOT
      let prodID = this.localStorageService.getItem('productID');
      let isSubscribedUser = false;
      if (prodID) {
        const prodIDs: Array<string> = prodID;
        prodIDs.forEach(pid => {
          if (!pid.toLowerCase().includes('gifts')) this.isSubscribed = true;
        });
      }

      if (window.innerWidth <= 991) {
        this.isMobileView = true;
      }
      if (window.location.pathname == '/followings-comics' && !this.isMobileView) {
        this.router.navigate(['/'])
      }
    }

    this.influencePoints = this.comicService.influencePoints;
    this.showAllComics = util.showAllComics();
    if (isPlatformBrowser(this.platform)) {
      this.getUserData(); // get user data for toggle btn...
      this.anonymousUser = util.isAnonymousUser();
      this.isComicPage = this.comicService.isComicPage();
    }
    this.urlSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.chviewChild && this.chviewChild.parentCall();
      } else if (event instanceof NavigationEnd) {
        this.chviewChild && this.chviewChild.parentCall();
      }
    });
    // -------------form for send me the link -----------------------
    this.myform = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10)
      ])
    });

    this.isComicPage = this.comicService.isComicPage();
    const user = firebase.auth().currentUser;
    if (user) {
      this.route.queryParams.subscribe(params => {
        // adding influence points to the user who shared comic
        this.comicService.provideInfluencePoint(params);
      });
    }

    // !this.isDirectoryPage ? this.isLoadingHeader = true : null;
    this.newComicService.getsidebarItemSelected().subscribe(val => {
      if (val && !val.sameItemClicked) {
        this.isLoadingHeader = true;
        this.action = null;
        if (val && val.menuItem === 'following') {
          this.chapterList = [];
          this.sidebarItemSelected = val;
          this.getFeedsOfStories(null);
        } else if (val && val.menuItem === 'home') {
          this.chapterList = [];
          this.sidebarItemSelected = val;
          this.getFeedsOfStories(null);
        }
        this.showAllComics = this.localStorageService.getItem('selectedMenuItem') === 'home';
      };
    });

    this.comicService.getInfiniteScroll().subscribe((event) => {
      this.infiniteScroll(event);
    });

    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;

    _self = this;

    this.subscriptions.openShareModalSubscription = this.newComicService.openShareModal$.subscribe(() => {
      this.openShareModal(this.shareModal);
    });

    this.subscriptions.followSubscription = this.newComicService.followSeriesSubject$.subscribe(() => {
      this.addRemoveLike();
    });
  }

  // To set cacheKey for home and following pages
  setCacheKey(cacheKey) {
    const feedPage = this.localStorageService.getItem('selectedMenuItem');
    if (this.currentUrl === '' && feedPage === 'following') {
      cacheKey = `${this.currentUrl}/following`;
    } else if (this.currentUrl === '' && (!feedPage || feedPage === 'home')) {
      cacheKey = `${this.currentUrl}/home`;
    }
  }

  infiniteScroll(event) {
    // TODO(Prince): Replace _self with this.action and this.previousAction.
    // Infinite scroll should only work on home, following and series page
    // if (!this.newComicService.isHomePage() && !this.isSeriesPage) return;
    if (!_self) return;
    let getFeeds = () => {
      if (_self.action) {
        if (_self.action != _self.previousAction) {
          _self.previousAction = _self.action;
          _self.getFeedsOfStories(_self.action, 'onScroll');
        }
      }
    }
    const scrollHeight = event.target.scrollHeight || (event.target.documentElement ? event.target.documentElement.scrollHeight : 0);

    const scrollTop = event.target.scrollTop || (event.target.documentElement ? event.target.documentElement.scrollTop : 0);
    const scrollThreshold = 2000;
    const difference = scrollHeight - scrollTop;
    if (isPlatformBrowser(_self.platform)) {
      if (_self.currentDevice == 'desktopBrowser') {
        if ((difference < scrollThreshold) && scrollTop != 0) {
          getFeeds();
        }
      } else {
        if ((difference < scrollThreshold) && scrollTop != 0) {
          getFeeds();
        }
      }
    }
  }

  // code to handle same menu click for home and following page for smart scrolling purpose
  public handleRefreshEvent() {
    const currentUrl = this.router.url;
    const updatedUrl = currentUrl.replace(/[\?&]reload=\d+/, '');
    this.router.navigateByUrl(updatedUrl);
  }

  // setIsOpenApp(flag?) {
  //   if (this.showNotifyForGuestUser && this.isComicPage) {
  //     this.isOpenApp = false;
  //     this.setFollowSeriesBanner(flag);
  //   } else {
  //     this.isOpenApp = this.sessionStorageService.getItem('isOpenApp') === 'false' ? false : true;
  //     this.setFollowSeriesBanner(flag);
  //   }
  // }

  // setFollowSeriesBanner(flag?) {
  //   if (flag) return this.followSeriesBanner = true;
  //   if (this.followBtnText === 'Follow' && !this.showNotifyForGuestUser && !((this.currentDevice == 'androidBrowser' || this.currentDevice == 'iosBrowser') && this.isOpenApp)) {
  //     this.followSeriesBanner = true;
  //   } else {
  //     this.followSeriesBanner = false
  //   }
  // }

  // async getcomicInfo(monthlyFreeComic) {
  //   this.isComicPage = this.comicService.isComicPage();
  //   if (!this.isComicPage) return;
  //   // const isSubscriber = this.localStorageService.getItem('productID');
  //   this.userAccessToComic = await this.comicService.checkComicReadAccess(this.currentUrl);
  //   const viewed = this.userAccessToComic.monthlyReadCounter.count;
  //   const diff = monthlyFreeComic - viewed;
  //   this.comicLeftToView = diff > 0 ? diff : 0;
  //   this.showNotifyForGuestUser = util.isAnonymousUser() && monthlyFreeComic;
  //   this.comicViewed = (this.userAccessToComic.isRead === 2);
  //   // const showToaster = this.sessionStorageService.getItem('showUnlockedToaster');
  //   // let storedURLs = this.sessionStorageService.getItem('unlockedURLs');
  //   // if (this.comicViewed || storedURLs.includes(this.currentUrl)) {
  //     this.showNotifyForGuestUser = false;
  //     // showToaster === this.currentUrl ? this.toastr.success('You’ve used 1 influence point to unlock this comic') : this.toastr.success('Previously viewed comic. Doesn’t affect free limit.');
  //     // this.sessionStorageService.removeItem('showUnlockedToaster');
  //   // }
  // }

  // closeOpenAppCard() {
  //   this.isOpenApp = fal
  //   this.sessionStorageService.setItem('isOpenApp', `${this.isOpenApp}`)
  // }
  // closeNotifyForSubscribe() {
  //   this.showNotifyForGuestUser = false;
  // }
  public navigateTo(uri) {
    switch (uri) {
      case '/tinyview': {
        this.router.navigate([uri]).then(() => {
          window.location.reload();
        });
      }
    }
  }

  private removeAction(arr, action) {
    // Find the index of the object with the action property.
    const index = arr.findIndex((obj) => obj.action === action);
    // If the object is found, remove it from the array.
    if (index !== -1) {
      arr.splice(index, 1);
    }
  }

  public getUserDetails() {
    this.$userDetails.subscribe(async (val) => {
      const pIDs = [];
      if (val.data.subscriptions.length) {
        val.data.subscriptions.map((res) => {
          this.productIds.push({
            id: res.productID,
            store: res.store
          });
          pIDs.push(res.productID);
        });
      }
      this.localStorageService.setItem('productID', JSON.stringify((pIDs)));
      this.comicService.productIDs$.next(JSON.stringify((pIDs)));

      const highestSubscription = this.comicService.getHighestSubscriptionFromArray(pIDs);
      this.chapterList = this.chapterList.filter((chapter: any) => {
        if (Array.isArray(chapter.inAppProducts)) {
          const show = chapter.inAppProducts.filter(product => [highestSubscription].includes(Object.keys(product)[0]) && product[Object.keys(product)[0]]);
          const nonSubscriberPanel = (chapter.inAppProducts.filter(product => !product[Object.keys(product)[0]])).length;
          const isSubscriber = this.productIds.length;
          return show.length || (isSubscriber ? false : nonSubscriberPanel);
        }
        return true;
      })
    })
  }

  public async getUserAlertsInfo() {
    const userData = await this.userService.getUserDetails();
    this.hasAnyChannel = this.comicService.userHasAnyChannel(userData.data);
    const alerts = userData.data.data.alerts;
    if (Object.keys(alerts).length === 0) this.followBtnText = 'Follow';
    alerts && Object.keys(alerts).map((data) => {
      const tinyviewRegex = /^\/tinyview/;
      const isTinyView = tinyviewRegex.test(this.currentUrl);
      const isHomeOrFollowing = ['', '/'].includes(this.currentUrl);
      let currentPageSeries = this.currentUrl;
      if (isTinyView || isHomeOrFollowing) currentPageSeries = 'tinyview';
      if (this.storyPage) currentPageSeries = this.seriesForNavigation;
      if (currentPageSeries && currentPageSeries.includes(data)) {
        this.isLiked = true;
      }
      this.followBtnText = this.isLiked ? 'Following' : 'Follow';
    });
  }

  public async getComicData(): Promise<void> {
    this.isLoadingHeader = true;
    this.isCover = this.comicService.isCoverPage();
    this.currentDevice = this.comicService.getOperatingSystem();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.fetchDone = false;
    this.resp = undefined;
    this.$userDetails.subscribe((res) => {
      this.showAllComics = this.localStorageService.getItem('selectedMenuItem') === 'home';
      this.hasApp = res.data.hasApp;
    });
    if (this.currentUrl == '/') {
      this.currentUrl = '';
    }
    if (!this.storyPage && (this.currentUrl == '' || this.currentUrl == '/' || this.isCover) && this.currentUrl != '/tinyview' && this.currentUrl != '/followings-comics') {
      this.getNewsFeedData(); // for getting news feed data for series pages...
    } else if (this.param) {
      const user = firebase.auth().currentUser;
      const UID = user ? user.uid : null;
      if (UID) {
        this.getStoryPageData(); //  for showing all comments on story page...
      }
    } else if (this.currentUrl != '/followings-comics' && !this.storyPage) {
      this.getComicChaptersData(); //  comic data for comic pages...
    }
  }

  public getUserData() {
    let value: number = 1;
    this.comicService.getUserDetails().then(async (val) => {
      this.getUserAlertsInfo();
      // recording referral points to the creators who shared comic
      const params = this.route.snapshot.queryParams;
      this.comicService.provideReferralPoint(params);

      this.showAllComics = this.localStorageService.getItem('selectedMenuItem') === 'home';
      this.influencePoints = val.data.influencePoints;
      this.alternateNumber = { countryCode: val.data.alternateIsoCountryCode, phone: val.data.alternatePhoneNumber };
      this.localStorageService.setItem('showAllComics', JSON.stringify(val.data.showAllComics));
      if (this.currentUrl === subscribeURL && this.route.snapshot.queryParams.paymentStatus === "success") {
        this.comicService.getUserDetails(); // to update the user details after successful subscription
        // to track new subscription in fbq pixel
        const subValue = this.localStorageService.getItem('subscription_data') ? this.localStorageService.getItem('subscription_data').value : '0';
        this.fbqService.trackEvent(FBQ_TRACKING_EVENTS.SUBSCRIPITON, { currency: 'USD', value: parseFloat(subValue) });
        this.localStorageService.removeItem('subscription_data');

        if (this.anonymousUser) {
          this.router.navigate([signInPhoneURL], { queryParams: { showSubscriptionMsg: true , isoCountryCode: this.alternateNumber && this.alternateNumber.countryCode, phoneNumber: this.alternateNumber && this.alternateNumber.phone && this.alternateNumber.phone.replace(/\s+/g, '') }});
        }
      }

      // Subscription handling
      const pIDs = [];
      if (val.data.subscriptions.length) {
        val.data.subscriptions.map((res) => {
          this.productIds.push({
            id: res.productID,
            store: res.store
          });
          pIDs.push(res.productID);
        });
      }
      this.localStorageService.setItem('productID', JSON.stringify((pIDs)));
      this.comicService.productIDs$.next(JSON.stringify((pIDs)));

      const user = firebase.auth().currentUser;
    // check for signin
      // const isSignedInUser = this.authService.isSignedInUser(user);
      // if (isSignedInUser) {
      //   // Fetching profile details with phone number
      //   let authType: string;
      //   if (user.phoneNumber) authType = AUTH_TYPE.PHONE;
      //   if (user.email) authType = AUTH_TYPE.EMAIL;
      //   if (user.phoneNumber || user.email) {
      //     const authValue = authType === AUTH_TYPE.PHONE ? user.phoneNumber : user.email
      //     this.userService.getUserProfileByPhoneOrEmail(authType, authValue).then((res) => {
      //       this.localStorageService.setItem('userData', JSON.stringify(res.result));
      //     })
      //   }

      // }

      this.showAllComics = this.localStorageService.getItem('selectedMenuItem') === 'home';
      if (this.showAllComics) {
        this.action = this.chapterList[this.chapterList.length - 1] ? this.chapterList[this.chapterList.length - 1] && this.chapterList[this.chapterList.length - 1].storyID : null;
      }
      this.comicService.showAllComics$.next(val.data.showAllComics);
      if (val.data) {
        if (value == 1) {
          this.getFeedsOfStories(this.action);
          value++;
        }
      }
      this.isFollowComic = !((Object.keys(val.data.alerts).length == 0 || ((Object.keys(val.data.alerts).includes('tinyview') && Object.keys(val.data.alerts).length == 1)) || ((Object.keys(val.data.alerts).includes('newsfeed.json') && Object.keys(val.data.alerts).length == 1))))
    }, err => {
      this.showAllComics = true;
      this.action = this.chapterList[this.chapterList.length - 1] ? this.chapterList[this.chapterList.length - 1] && this.chapterList[this.chapterList.length - 1].storyID : null;
      this.isFollowComic = false;
      if (value == 1) {
        this.getFeedsOfStories(this.action);
        value++;
      }
    })
  }

  public async getComicFeedData() {
    this.isLoadingHeader = true;
    if (this.param) {
      this.storyID = this.param;
    } else {
      if (isPlatformBrowser(this.platform)) {
        const $this = this;
        this.fetchDone = true;
        const user = firebase.auth().currentUser;
        if (user) {
          const path = $this.currentUrl;
          let comicPage: boolean;
          if (!['/', subscribeURL, '/tinyview/comic-series-directory'].includes(path) && !path.includes('/edit')
            && !path.includes('/story/') && path.split('/').length > 2) {
            comicPage = true;
          }
          if (!comicPage && !this.storyPage) {
            this.comicService.viewsForExternalLink(`${$this.currentUrl}/index.json`);
          }
          if (['', '/'].includes($this.currentUrl)) {
            const getPageInfo = await this.apiService.send(API_KEYS.GET_PAGEVIEW_INFO); // pageInfo API
            getPageInfo({
              pageUrl: `/tinyview/index.json`
            }).then((result) => {
              $this.likeCount = result.data.likeCount;
              if ($this.likeCount == null) {
                $this.likeCount = 0;
              }
            })
          }
          const getPageInfo = await this.apiService.send(API_KEYS.GET_PAGEVIEW_INFO); // pageInfo API
          getPageInfo({
            pageUrl: ['', '/'].includes($this.currentUrl) ? `/index.json` : `${$this.currentUrl}/index.json`
          }).then(async (result) => {
            $this.pageInfo = result;
            if ($this.currentUrl != '' && $this.currentUrl != '/') {
              $this.likeCount = result.data.likeCount;
            }
            $this.giftsCounts = result.data.giftsCount;
            $this.giftedItems = result.data.giftedItems;
            $this.storyID = result.data.storyId;
            $this.commentCount = result.data.commentCount;
            $this.refType = result.data.refType;
            $this.repostCount = result.data.repostCount;
            if (result.data.refType == 'REPOST') {
              $this.refId = result.data.refId;
            }
            $this.isLiked2 = result.data.isLiked;
          });
        }
      }
    }
    this.comicService.getComicTitle().subscribe(res => {
      for (var key in res) {
        if (['', '/'].includes(this.currentUrl)) {
          this.seriesTitle = '';
          this.seriesImage = '';
        } else if (['/tinyview/comic-series-directory'].includes(this.currentUrl)) {
          this.seriesTitle = 'Tinyview';
          this.seriesImage = 'https://storage.googleapis.com/tinyview-dev.appspot.com/tinyview/tinyview-profile.jpg';
        } else {
          if (!res.hasOwnProperty(key)) continue;
          const series = this.comicService.formatSeriesName(this.currentUrl)
          if (this.currentUrl && series.includes(key)) {
            let path = COMIC_IMG_API;
            this.seriesImage = `${path}/` + key + '/' + key + '-profile.jpg';
            this.seriesHomeNavigation = `/${key}`;
            this.seriesTitle = res[key].title;
            break;
          }
        }
      }
    });
  }

  public async getNavigationData() {
    const series = this.currentUrl.split('/')[1];
    // input data for a series's latest and first episodes
    const input = {
      forFeedPage: false,
      storyID: '',
      series: series,
      action: '',
      isContinueReading: true
    };
    const res = await this.comicService.getComicNavigation(input);
    this.continueReading = res.data || null;
    if (Object.keys(this.continueReading).length !== 0) {
      this.continueReading['isContinueReading'] = true;
    }
    this.newComicService.changeContinueReadingData(this.continueReading || {});
  }
  // For Latest and first comic's navigation on Series page
  navigateOnClick(data) {
    if (data.actionType != "website") {
      this.router.navigate([data.action.replace('index.json', '')])
    } else {
      window.open(data.action, "_blank");
    }
  }

  // public showALlComments(storyID) {
  //   if (storyID) {
  //     this.feedService.getStoryByID(storyID).then((res) => {
  //       if (res.data.data.commentCount) {
  //         if (isPlatformBrowser(this.platform)) {
  //           var sp = window.scrollY;
  //           this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
  //           this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
  //         }
  //         this.router.navigate(['story', this.storyID]);
  //       }
  //     }, err => {
  //       console.log(err);
  //     });
  //   }
  // }

  public getNewsFeedData() {
    let feedStories: any = [];
    this.comicService.getNewsFeedChapters(this.currentUrl).subscribe(async (resp) => {
      this.resp = null;
      this.resp = resp;
      this.record = Number(this.resp.comics.showComments);
      if (this.resp.comics.links && this.resp.comics.links.website) {
        this.webUrl = this.comicService.extractDomain(this.resp.comics.links.website);
      }
      this.showMetaTags(resp);
      // for newsfeed panels ---------------------------------------------------------------
      this.resp.comics.panels.filter((item: any) => {
        return this.panelService.isAllowedPanelVersion(item);
      }).map((item: any) => {
        if (item.template == templateTypes.toc || item.template == templateTypes.story && !item.storyID && item.action) {
          this.feedService.getStory(item.action).then(res => {
            if (res && res.data && res.data.data) {
              item.storyID = res.data.data.storyID;
              item.isLiked = res.data.data.isLiked;
              item.views = res.data.data.views;
              item.likeCount = res.data.data.likeCount;
              item.repostCount = res.data.data.repostCount
              item.commentCount = res.data.data.commentCount;
              item.refType = res.data.data.refType;
              item.giftsCount = res.data.data.giftsCount;
              item.giftedItems = res.data.data.giftedItems;
            }
          }, err => console.log(err));
        }
        if (item.title == "My Comics" && item.template == templateTypes.newsfeed) {
          item = {
            title: item.title,
            titleOn: item.titleOn
          };
        }
        feedStories.push(item);
      });
      this.chapterList = feedStories;

    }, () => {
      this.errorMsg = 'No data found!'
      this.fetchDone = true;
    });
    this.setToc();
    if (feedStories) {
      this.chapterList = feedStories;
    }
  }
  public setToc() {
    let headerPath = this.comicService.resolvePath(this.currentUrl, this.comicService.formatSeriesHeaderName(this.currentUrl));
    if (['', '/'].includes(this.currentUrl)) return
    this.comicService.getHeaderChapters(headerPath)
      .subscribe(
        async (res) => {
          if (res && res.comics.panels) {
            if (this.headerArray.length == 0) {
              for (let i = 0; i < res.comics.panels.length; i += 1) {
                const isAllowedPanelVersion = this.panelService.isAllowedPanelVersion(res.comics.panels[i]);
                if (!isAllowedPanelVersion) {
                  continue;
                }

                if (res.comics.panels[i] && res.comics.panels[i].template === 'toc') {
                  if (res.comics.panels[i].action) {
                    this.actionUrlArray.push(res.comics.panels[i].action);
                  }
                  if (this.chapterList && this.chapterList[0].template && this.chapterList[0].template === res.comics.panels[i].template) {
                    return
                  }
                  this.headerArray.push(res.comics.panels[i]);
                  // if (!this.comicService.isComicPage() && !this.isMobileView) {
                  //   res.comics.panels[i]['panelBorder'] = false;
                  // }
                  this.chapterList.unshift(res.comics.panels[i]);
                }
              }
            }
          }
        });
  }

  public storyPageActionURLHandling () {
    let getStory;
      if (this.param === 'action') {
        const actionURL = this.route.snapshot.queryParams['actionURL'];
        getStory = this.feedService.getStory(actionURL);
      } else {
        getStory = this.feedService.getStoryByID(this.param);
      }

      return getStory;
  }

  public getStoryPageData() {
    this.storyID = this.param;
    if (this.storyID) {
      let getStory = this.storyPageActionURLHandling();

      getStory.then(async resp => {
        this.comicService.getComicTitle().subscribe(async (series) => {
          if (Object.keys(series).some(key => key === resp.data.data.series)) {
            this.currentSeriesDetails['title'] = series[`${resp.data.data.series}`].title;;
            this.currentSeriesDetails['action'] = series[`${resp.data.data.series}`].action;
          }
        });
        if (this.storyPage && resp.data && resp.data.data && resp.data.data.action) {
          this.comicService.viewsForExternalLink(resp.data.data.action);
        }
        this.param = this.storyID = resp.data.data.storyID;
        const scheduleAlertsURL = resp.data.data.action && resp.data.data.action.replace('/index.json', '');
        this.feedService.emitComicAction(scheduleAlertsURL);
        this.resp = resp;
        const comicData = this.resp.data.data;
        this.resp.data.data = await this.appService.updateComicDataWithConfig(comicData);
        this.resp['comics'] = { ogImage: resp.data.data.image };
        // delete this.resp.data.data['panelBorder'];
        this.chapterList.push(this.resp.data.data);
        this.giftsCounts = this.resp.data.data.giftsCount;
        this.giftedItems = this.resp.data.data.giftedItems;
        this.likeCount = this.resp.data.data.likeCount;
        this.commentCount = this.resp.data.data.commentCount;
        this.refType = this.resp.data.data.refType;
        this.repostCount = this.resp.data.data.repostCount;
        this.viewCount = this.resp.data.data.views;
        this.actionType = this.resp.data.data.actionType;
        this.actionForStory = (this.resp && this.resp.data && this.resp.data.data && this.resp.data.data.user && this.resp.data.data.user.action) ? this.resp.data.data.user.action : '';
        this.comicService.getComicTitle().subscribe(res => {
          let seriesName = this.resp.data.data.series || 'tinyview';
          const sideBGColor =
            (seriesName &&
              res[seriesName.replace("/", "")] &&
              res[seriesName.replace("/", "")].style &&
              res[seriesName.replace("/", "")].style.notificationColor);
          this.storyPage && document.documentElement.style.setProperty(`--bg-color`, '#FFF');
          document.documentElement.style.setProperty(`--side-bg-color`, sideBGColor);
        })

        if (this.resp.data.data.template == templateTypes.story) {
          this.seriesTitle = this.resp.data.data.user.name;
          if (this.resp.data.data.refType == templateTypes.story.toUpperCase()) {
            this.seriesImage = COMIC_IMG_API + this.resp.data.data.user.image;
          } else {
            this.seriesImage = this.resp.data.data.user.image;
          }
          if (this.resp.data.data.user.action)
            this.seriesHomeNavigation = this.resp.data.data.user.action.replace('/index.json', '').replace('/footer.json', '');
        }
        this.feedService.getAllStoryLikes([this.storyID]).then((res) => {
          this.isLiked2 = res.data.data[0].isLiked;
        });
        this.comicDate = this.resp.data.data.updatedAt ? this.comicService.formatDate(new Date(this.resp.data.data.updatedAt)) : '';
        this.titleService.setTitle(this.resp.data.data.title);
        this.metaDescription = this.resp.data.data.comment ? this.resp.data.data.comment.replace(/(<([^>]+)>)/ig, "") : this.resp.data.data.description.replace(/(<([^>]+)>)/ig, "");
        if (this.resp.data.data.image) {
          this.imgPath = `${COMIC_IMG_API}${this.comicService.resolvePath(this.resp.data.data.action.replace('/index.json', ''), this.resp.data.data.image)}`;
        } else {
          this.imgPath = 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg';
        }
        // let url = this.resp.data.data.action.replace('/index.json', '');
        // const metaData = [{
        //   property: 'twitter:description',
        //   content: this.metaDescription
        // },
        // {
        //   property: 'twitter:image',
        //   content: `${this.imgPath}`
        // },
        // {
        //   property: 'twitter:title',
        //   content: this.resp.data.data.title
        // },
        // {
        //   property: 'og:title',
        //   content: this.resp.data.data.title
        // },
        // {
        //   property: 'og:description',
        //   content: this.metaDescription
        // },
        // {
        //   property: 'og:image',
        //   content: `${this.imgPath}`
        // },
        // {
        //   property: 'og:url',
        //   content: `https://tinyview.com${url}`
        // },
        // ];
        // for (const metaProperty of metaData) {
        //   this.meta.updateTag({
        //     property: metaProperty.property,
        //     content: metaProperty.content
        //   });
        // }
        this.refType = this.resp.data.data.refType;
        if (this.resp.data && this.resp.data.data && this.resp.data.data.user && this.resp.data.data.user.name) {
          this.seriesName = this.resp.data.data.user.name;
        } else {
          this.seriesName = `Tinyview`
        }
        this.fetchDone = true;

        if (!['tinyview', 'index.json'].includes(this.resp.data.data.series)) {
          // Prev/Next comic nav panel handling
          this.seriesForNavigation = this.resp.data.data.series;
          this.extActionURL = this.resp.data.data.action;
          this.getUserAlertsInfo();
          if (this.storyPage) {
            // Prev/Next btns on story page on bottom nav bar handling
            const episodeData = await this.comicService.getEpisodesList({
              forFeedPage: false,
              storyID: this.storyID,
              series: this.resp.data.data.series,
              action: this.resp.data.data.action
            });

            const storyBottomNavData = { 
              episodes: episodeData.data.episodes,
              seriesHome: `/${this.resp.data.data.series}`,
              currentComicAction: this.resp.data.data.action
            };

            this.newComicService.triggerEpisodesListSubject(storyBottomNavData || {});
          }

          // this.chapterList.push({
          //   "title": "Episodes",
          //   "button": "All Episodes",
          //   "template": "carousel",
          //   "carouselType": "episodes",
          //   "list": this.seriesEpisodes,
          //   "seriesHomeNavigation": `/${this.resp.data.data.series}`,
          //   "currentComicAction": this.resp.data.data.action
          // });

          // this.chapterList.push({
          //   "title": "Home Feed",
          //   "button": "Home",
          //   "template": "carousel",
          //   "carouselType": "home",
          //   "list": this.feedEpisodes,
          //   "currentComicAction": this.resp.data.data.action
          // });
        } else {
          const storyBottomNavData = { 
            episodes: [],
            seriesHome: `/${this.resp.data.data.series}`,
            currentComicAction: this.resp.data.data.action
          };

          // We will take user to all series on list-icon click from story page of subscribe and all series.
          if (this.resp.data.data.series === 'tinyview') {
            storyBottomNavData['seriesHome'] = allSeriesURL;
          }

          this.newComicService.triggerEpisodesListSubject(storyBottomNavData || {});
        }
      }, err => {
        console.log(err)
      });
    }
  }

  public getComicChaptersData() {
    this.isLoadingHeader = true;
    let query: string;
    //TODO: integrate api to get all urls of unlocked comics.
    if (this.router.url.indexOf('?fn=') !== -1) {
      let queryStack = this.router.url.split('?fn=');
      query = queryStack[queryStack.length - 1];
    }
    // Calls for subscribe/index.json
    this.comicService.getComicChapters(this.currentUrl, query)
      .subscribe(async (resp) => {
        this.fetchDone = true;
        if (isPlatformBrowser(this.platform)) {
          this.admin = this.localStorageService.getItem('isAdmin');
        }
        if ((resp && resp.comics.datetime && (new Date(resp.comics.datetime) >= new Date())) && !this.admin) {
          this.errorMsg = 'No data found!'
          this.fetchDone = true;
        } else {
          this.resp = null;
          resp.comics.panels = resp.comics.panels.reduce((acc: any[], item: any) => {
            const isPanelAllowed = this.panelService.isAllowedPanelVersion(item)
            if (isPanelAllowed) {
              acc.push(item);
            }
            return acc;
          }, []);
          this.resp = resp;
          const comicData = this.resp && this.resp.comics;
          this.hasBonusPanel = this.comicService.hasBonusTemplate(comicData.panels);
          this.hasFollowBtn = this.comicService.hasFollowBtn(comicData.panels);
          this.resp.comics = await this.appService.updateComicDataWithConfig(comicData);
          this.comicComments = resp.comics.comments;

          if (this.currentUrl.split('/').length - 1 > 1) {
            this.comicDate = this.resp.comics.datetime ? this.comicService.formatDate(new Date(this.resp.comics.datetime)) : '';
          } else {
            this.comicDate = '';
          }
          if (this.resp.comics.links && this.resp.comics.links.website) {
            this.webUrl = this.comicService.extractDomain(this.resp.comics.links.website);
          }
          this.showMetaTags(this.resp);
          if (this.comicService.cacheComic[this.location.pathname]) {
            if (this.chapterList.length === 0) {
              // this.chapterList = this.comicService.cacheComic[this.location.pathname]; TODO: need to handle caching
              await this.getNavigationData();
            }
            await this.setComicPannelBasedOnUserAccess(this.resp.comics);
          }
          if (isPlatformBrowser(this.platform) && !this.comicService.cacheComic[this.location.pathname]) {
            await this.prepareComicsData();
          }
          this.store.dispatch(new PostActions.EditText(this.resp.comics.title));
          this.errorMsg = null;
        }
        this.isLoadingHeader = false;
      }, err => {
        this.isLoadingHeader = false;
        this.errorMsg = 'No data found!'
      });
  }

  async checkUserComicAccess() {
    return await this.comicService.checkUserComicAccess();
  }

  async setComicPannelBasedOnUserAccess(comicData) {
    const userInfo = await this.checkUserComicAccess();
    this.isComicPage = this.comicService.isComicPage();
    const premiumComicInfo = await this.comicService.checkPremiumComic(this.currentUrl, comicData['show-to'] && comicData['show-to'].key, comicData.influencePrice);
    let isRead: number = 1;
    if (premiumComicInfo.isPremiumComic && !premiumComicInfo.hasAccess) {
        isRead = 0;
    } else if (!userInfo.hasAllComicAccess && !this.tinyviewPage && !premiumComicInfo.isPremiumComic) {
      this.userAccessToComic = await this.comicService.checkComicReadAccess(this.currentUrl);
      isRead = (this.userAccessToComic.isRead === 2) || (this.userAccessToComic.isRead === 1) ? 1 : 0;
      const comicViewed = (this.userAccessToComic.isRead === 2);

      if (comicViewed) {
        this.toastr.success('Previously viewed comic. Doesn’t affect your comic limit as a guest.');
      }

      if (this.userAccessToComic.isRead == 0) {
        const comicLimit = {
          action: signUpURL,
          height: 2000,
          image: '/tinyview/app/tinyview-free-comics-limit-with-button.jpg',
          md5sum: "c01e567ce5cbe8fa596465725cec7f44",
          width: 1600,
          template: templateTypes.comicLimit
        }
        const influencePanelForComicLimit = {
          action: 'influencePanelForComicLimit'
        }
        const hasPannel = this.chapterList.some(item => {
          return item.action === influencePanelForComicLimit.action;
        })
        if (!hasPannel) {
          this.chapterList.push(comicLimit);
          this.chapterList.push(influencePanelForComicLimit); // commented for new functionality in COMP-1028
        }
      }
    }
    if (this.isComicPage && !this.isSubscribePage) {
      this.comicService.viewsForExternalLink(`${this.currentUrl}/index.json`, isRead);
    }


    // if (this.isComicPage && !this.isSubscribePage && !userInfo.hasAllComicAccess) {
    //   // await this.getcomicInfo(userInfo.monthlyFreeComic);
    //   this.userAccessToComic = await this.comicService.checkComicReadAccess(this.currentUrl);
    //   isRead = (this.userAccessToComic.isRead === 2) || (this.userAccessToComic.isRead === 1) ? 1 : 0;
    //   this.comicService.viewsForExternalLink(`${this.currentUrl}/index.json`, isRead);
    //   // let storedURLs = this.sessionStorageService.getItem('unlockedURLs');
    //   // if (!storedURLs) {
    //   //   await new Promise(resolve => setTimeout(resolve, 2500));
    //   //   storedURLs = this.sessionStorageService.getItem('unlockedURLs');
    //   // }
    //   // let urls = storedURLs || [];
    //   if (this.userAccessToComic.isRead == 0) {
    //     const comicLimit = {
    //       action: signUpURL,
    //       height: 2000,
    //       image: '/tinyview/app/tinyview-free-comics-limit-with-button.jpg',
    //       md5sum: "c01e567ce5cbe8fa596465725cec7f44",
    //       width: 1600,
    //       template: templateTypes.comicLimit
    //     }
    //     const influencePanelForComicLimit = {
    //       action: 'influencePanelForComicLimit'
    //     }
    //     const hasPannel = this.chapterList.some(item => {
    //       return item.action === influencePanelForComicLimit.action;
    //     })
    //     if (!hasPannel) {
    //       this.chapterList.push(comicLimit);
    //       this.chapterList.push(influencePanelForComicLimit); // commented for new functionality in COMP-1028
    //     }
    //   }
    // } else
    // if (isPremiumComic.hasAccess && this.isComicPage) {

    // }
  }

  private async prepareComicsData() {
    this.chapterList = [];
    this.footerArray = [];
    this.actionUrlArray = [];
    this.navChapterList = [];
    let isComicLimit = false;
    if ((this.resp && this.resp.comics.previewPanels != null) && (this.resp.comics.previewPanels > this.resp.comics.panels.length)) {
      this.previewPanels = this.resp.comics.panels.length;
    } else if (this.resp && this.resp.comics.previewPanels != null) {
      this.previewPanels = this.resp.comics.previewPanels;
    }
    if (this.previewPanels == 0) {
      this.previewPanelCount = false;
    } else {
      this.previewPanelCount = true;
    }

    this.isBonusPanelComic = this.resp.comics.panels.find(p => p.template === templateTypes.bonus)
    let isBonusPanelUnlocked = false;
    let prodID = this.localStorageService.getItem('productID');
    let isSubscribedUser = false;
    if (prodID) {
      const prodIDs: Array<string> = prodID;
      prodIDs.forEach(pid => {
        if (!pid.toLowerCase().includes('gifts')) isSubscribedUser = true;
      });
    }
    await this.setComicPannelBasedOnUserAccess(this.resp.comics);

    if (this.isPaid || (this.isBonusPanelComic && !isSubscribedUser)) {
      let storedURLs = this.sessionStorageService.getItem('unlockedURLs');
      if (!storedURLs) {
        await new Promise(resolve => setTimeout(resolve, 2500));
        storedURLs = this.sessionStorageService.getItem('unlockedURLs');
      }
      let urls = storedURLs || [];
      if (urls.includes(`${this.currentUrl}/index.json`)) {
        if (this.isBonusPanelComic) isBonusPanelUnlocked = true;
        this.isPaid = false;
      }
    }
    if (!this.isPaid) {
      this.previewPanelCount = true;
    }
    var isValid = 0;

    isComicLimit = this.chapterList.some(panel => panel.action === 'influencePanelForComicLimit');

    // Removing 1st panel from panels array because preview image should not be in panels array because we don't show it twice on comic page
    if (this.resp.comics.ogImage === this.resp.comics.panels[0].image) {
      this.resp.comics.panels.shift();
    }

    if (!isComicLimit) for (let i = 0; i < this.resp.comics.panels.length; i++) {
      if (this.resp.comics.panels[i].datetime) {
        this.panelPublishDate = new Date(this.resp.comics.panels[i].datetime) <= new Date() ? true : false
      }
      if (this.previewPanelCount && this.panelPublishDate && this.resp && this.comicService.isPlatformAvailable(this.resp.comics.panels[i])) {
        if (this.resp.comics.panels[i]) {
          if (!this.isCover && !['/tinyview/comic-series-directory', subscribeURL].includes(this.currentUrl)) {
            if (this.resp.comics.panels[i].action) {
              let storyMeta = { image: null };
              if (this.resp.comics.panels[i].actionType === 'website') {
                storyMeta['url'] = this.resp.comics.panels[i].action || '';
                storyMeta['description'] = this.resp.comics.panels[i].description || '';
                storyMeta['title'] = this.resp.comics.panels[i].title || '';
                storyMeta['image'] = this.resp.comics.panels[i].image || '';
                storyMeta['series'] = this.resp.comics.panels[i].series || '';
                this.feedService.getStory(this.resp.comics.panels[i].action, storyMeta.image, storyMeta).then(res => {
                if (res) {
                  this.resp.comics.panels[i].storyID = res.data.data.storyID;
                  this.resp.comics.panels[i].isLiked = res.data.data.isLiked;
                  this.resp.comics.panels[i].views = res.data.data.views;
                  this.resp.comics.panels[i].likeCount = res.data.data.likeCount;
                  this.resp.comics.panels[i].repostCount = res.data.data.repostCount;
                  this.resp.comics.panels[i].commentCount = res.data.data.commentCount;
                  this.resp.comics.panels[i].refType = res.data.data.refType;
                  this.resp.comics.panels[i].giftsCount = res.data.data.giftsCount;
                  this.resp.comics.panels[i].giftedItems = res.data.data.giftedItems;
                }
              }, err => {
                console.log(err)
                });
              }
            }
          }
          if (this.resp && this.resp.comics && this.resp.comics.ogImage !== this.resp.comics.panels[i].image) { // ask APP team about this condition in case of premium comic
            this.chapterList.push(this.resp.comics.panels[i]);
            // if (this.resp.comics.panels[i].template === 'bonus') {
            // }
          }
        }
      }

      // Conditions for premium comic
      if (this.isPaid && this.resp.comics.previewPanels && !isSubscribedUser) {
        if (this.resp.comics.panels[i].platforms) {
          if (!this.comicService.isPlatformAvailable(this.resp.comics.panels[i])) {
            isValid = isValid + 1;
          }
        }
        if (i === this.previewPanels - 1 && isValid < 1) {
          break;
        }
        if (i === this.previewPanels && isValid == 1) {
          break;
        }
        if (i === this.previewPanels + 1 && isValid > 1) {
          break;
        }
      } else if (this.isPaid && (i == (Math.floor(this.resp.comics.panels.length / 3))) && !isSubscribedUser) {
        break;
      }
    }

    const storedURLs = this.sessionStorageService.getItem('unlockedURLs');
    let urls = storedURLs || [];
    // Check to show Unlock Premium Comic panel or not
    if (this.isPaid && !isSubscribedUser && !urls.includes(`${this.currentUrl}/index.json`)) { //  && !this.isBonusPanelComic - removed this check because of a PREMIUM comic was having BONUS panel
      // only insert this panel if comicLimit panel is not there, bcs we don't want to show both
      if (!this.chapterList.find((chapter) => chapter.template === templateTypes.comicLimit)) {
        const appPurchase = {
          action: '/tinyview/subscribe/index.json',
          height: 2000,
          image: '/tinyview/app/tinyview-premium-comic-with-button.jpg',
          md5sum: "c01e567ce5cbe8fa596465725cec7f43",
          width: 1600,
          template: 'premium'
        }
        this.chapterList.push(appPurchase);
      }
    }

    // this.setComicNavPanel();
    // if (this.seriesTitle === 'Tinyview') {
    //   this.seriesHomeNavigation = '/tinyview';
    // }

    // Comic panel to be shown at the bottom of comic page (COMIC PAGE EXTRA DETAILS)
    this.chapterList.push({ comments: this.resp.comics.comments, comicDescription: (this.resp.comics.description || this.resp.comics.comments), comicTitle: this.resp.comics.title, seriesImage: this.seriesImage, comicImage: this.resp.comics.ogImage, seriesHomeNavigation: this.seriesHomeNavigation, seriesTitle: this.seriesTitle, record: 2 });

    this.actionUrlArray = [];
    this.actionUrlArray.push('/tinyview/index.json');
    const actionArray = this.actionUrlArray;
    if (this.chapterList) {
      for (let data = 0; data < this.chapterList.length; data++) {
        if (this.chapterList[data].action != null) {
          this.actionUrlArray.push(this.chapterList[data].action);
        }
      }
    }
    if (this.actionUrlArray.length) {
      var commonUrls: any;
      if (this.actionUrlArray.length && this.cacheData.length) {
        commonUrls = this.actionUrlArray.filter((val: any) => !this.cacheData.includes(val));
      } else {
        commonUrls = this.actionUrlArray
      }
      const pageInfoData = this.comicService.pageInfoData(commonUrls)
      pageInfoData.then((result) => {
        if (result && result.data) {
          this.cacheData = this.actionUrlArray;
          for (var i = 0; i < this.chapterList.length; i++) {
            if (this.chapterList[i].template == templateTypes.bonus || this.chapterList[i].template == templateTypes.directory || this.chapterList[i].template == templateTypes.story || this.chapterList[i].template == templateTypes.episodes || this.chapterList[i].format == templateTypes.toc || this.chapterList[i].format == templateTypes.story) {
              for (var j = 0; j < this.actionUrlArray.length; j++) {
                if (this.chapterList[i].action && this.actionUrlArray[j]) {
                  if (this.chapterList[i].action == this.actionUrlArray[j]) {
                    if (result.data[this.actionUrlArray[j]]) {
                      this.chapterList[i].viewCount = result.data[this.actionUrlArray[j]] ? result.data[this.actionUrlArray[j]].viewCount : 0;
                      this.chapterList[i].likeCount = result.data[this.actionUrlArray[j]] ? result.data[this.actionUrlArray[j]].likeCount : 0;
                      if (this.actionUrlArray[j] == '/index.json') {
                        this.chapterList[i].likeCount = result.data['/tinyview/index.json'] ? result.data['/tinyview/index.json'].likeCount : 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }, err => {
      });
    }
    const tinyviewRegex = /^\/tinyview/;
    const isTinyView = tinyviewRegex.test(this.currentUrl);
    if (!isTinyView && this.currentUrl != '/') {
      let footerPath = this.comicService.resolvePath(this.currentUrl, this.resp && this.resp.comics && this.resp.comics.footer ? this.resp.comics.footer : this.comicService.formatSeriesName(this.currentUrl));
      this.comicService.getFooterChapters(footerPath)
        .subscribe(
          async (res) => {
            if (res && res.comics && res.comics.panels && !this.isCover) {
              if (this.footerArray.length == 0) {
                for (let i = 0; i < res.comics.panels.length; i += 1) {
                  const isAllowedPanelVersion = this.panelService.isAllowedPanelVersion(res.comics.panels[i]);
                  if (!isAllowedPanelVersion) {
                    continue;
                  }
                  if (res.comics.panels[i] && this.comicService.isPlatformAvailable(res.comics.panels[i]) && this.subscribedComic(res.comics.panels[i])) {
                    if (res.comics.panels[i].action) {
                      this.actionUrlArray.push(res.comics.panels[i].action);
                    }
                    this.footerArray.push(res.comics.panels[i]);
                    this.chapterList.push(res.comics.panels[i]);
                  }
                  if (res.comics.panels[i].template == templateTypes.episodes) {
                    this.actionForFooter = res.comics.panels[i].action;
                  }
                }
              }
              var commonUrls: any;
              if (this.actionUrlArray.length && this.cacheData.length) {
                commonUrls = this.actionUrlArray.filter((val: any) => !this.cacheData.includes(val));
              } else {
                commonUrls = this.actionUrlArray
              }
              const pageInfoData = await this.comicService.pageInfoData(commonUrls)
              this.cacheData = this.actionUrlArray;
  
              // To get episodes
              this.isComicNavLoading = true;
              const data = await this.comicService.getEpisodes(this.storyID);
  
              if (this.isComicPage) {
                // Prev/Next btns on story page on bottom nav bar handling
                const storyBottomNavData = { 
                  episodes: data.data.episodes
                };
                this.newComicService.triggerEpisodesListSubject(storyBottomNavData || {});
              }
              
              this.isComicNavLoading = false;
              data.data.episodes && data.data.episodes.forEach(item => this.seriesEpisodes.push(item));
              data.data.feed && data.data.feed.forEach(item => this.feedEpisodes.push(item));
              for (var i = 0; i < this.chapterList.length; i++) {
                if (this.chapterList[i].template == templateTypes.bonus || this.chapterList[i].template == templateTypes.directory || this.chapterList[i].template == templateTypes.story || this.chapterList[i].template == templateTypes.episodes) {
                  for (var j = 0; j < this.actionUrlArray.length; j++) {
                    if (this.chapterList[i].action == this.actionUrlArray[j]) {
                      this.chapterList[i].viewCount = pageInfoData.data[actionArray[j]] ? pageInfoData.data[actionArray[j]].viewCount : 0;
                      this.chapterList[i].likeCount = pageInfoData.data[actionArray[j]] ? pageInfoData.data[actionArray[j]].likeCount : 0;
                    }
                  }
                }
                if (this.chapterList[i].template === templateTypes.carousel) {
                  this.chapterList[i] = {
                    ...this.chapterList[i],
                    list: this.getListForCarousel(this.chapterList[i]),
                    seriesHomeNavigation: this.seriesHomeNavigation
                  };
                }
              }
            }
          });
    }

    this.setToc();
    if (this.currentUrl == subscribeURL) {
      this.comicService.$userDetails.subscribe(async (val) => {
        const pIDs = [];
        if (val.data.subscriptions.length) {
          val.data.subscriptions.forEach((r) => {
            this.chapterList.map(data => {
              if (data.title == upgradeTitle) {
                for (var res of data.inAppProducts) {
                  if (Object.keys(res)[0] == r.productID) {
                    this.showUpgradeTitle = r.productID;
                  }
                }
              }
            });
            pIDs.push(r.productID);
          })
        }
        this.localStorageService.setItem('productID', JSON.stringify((pIDs)));
        this.comicService.productIDs$.next(JSON.stringify((pIDs)));
      })
    }
    // this.comicService.cacheComic[this.currentUrl] = this.chapterList; TODO: need to handle caching
  }

  private getListForCarousel(chapterItem: any): any[] {
    switch (chapterItem.carouselType) {
      case carouselType.episodes:
        return this.seriesEpisodes;
      case carouselType.home:
        return this.isFromFollowing ? [] : this.feedEpisodes;
      case carouselType.following:
        return this.isFromFollowing ? this.feedEpisodes : [];
      case carouselType.list:
        return chapterItem.list || [];
      default:
        return [];
    }
  }

  public getFeedsOfStories(value: string, event?: string) {

    !this.isDirectoryPage && event !== 'onScroll' && !this.isComicPage && !this.isSubscribePage && !this.storyPage && !this.showListViewGIF ? this.isLoadingHeader = true : null;
    this.isSubscribePage || this.isComicPage || this.storyPage ? this.isLoadingHeader = false : null;
    if (['', '/', '/followings-comics'].includes(this.currentUrl)) {
      this.showFeedStory = true;

      if (['home', 'series', null].includes(this.localStorageService.getItem('selectedMenuItem'))) {
        if (this.feedsSubscription) {
          this.feedsSubscription.unsubscribe();
        }
        if (this.homeFeedsSubscription) {
          this.homeFeedsSubscription.unsubscribe();
        }
        this.homeFeedsSubscription = this.feedService.getAllComics(value).subscribe((resp) => {
          let chapList = [];
          if (this.chapterList.length > 0) {
            chapList = this.chapterList.slice();
          } else {
            const latestTitle = {
              title: "My Comics",
              titleOn: "Latest Comics"
            }
            chapList.push(latestTitle);
          }
          this.fetchDone = true;
          resp.data.data.map((element: { storyID: any; }) => {
            this.storyIds.push(element.storyID);
          });
          this.feedService.getAllStoryLikes(this.storyIds).then((res) => {
            this.allStoryLikes = res.data;
            this.showFeedStory = false;
          }, err => {
            this.fetchDone = true;
          }).then( async () => {
            for (let i = 0; i < resp.data.data.length; i++) {
              const comicData = resp.data.data[i];
              resp.data.data[i] = await this.appService.updateComicDataWithConfig(comicData);
              if (resp.data.data[i].updatedAt) {
                this.panelPublishDate = new Date(resp.data.data[i].updatedAt) <= new Date() ? true : false
              }
              if (this.subscribedComic(resp.data.data[i]) && this.panelPublishDate) {
                chapList.push(resp.data.data[i]);
              }
            }
            for (let i = 0; i < chapList.length; i++) {
              if (this.allStoryLikes && this.allStoryLikes.data && this.allStoryLikes.data.length) {
                for (let j = 0; j < this.allStoryLikes.data.length; j++) {
                  if (chapList[i].storyID == this.allStoryLikes.data[j].storyId) {
                    chapList[i].isLiked = this.allStoryLikes.data[j].isLiked
                    chapList[i].giftedItems = this.allStoryLikes.data[j].giftedItems;
                  }
                }
              }
            }

            if (!this.localStorageService.getItem('selectedMenuItem') || this.localStorageService.getItem('selectedMenuItem') === 'home' || this.localStorageService.getItem('selectedMenuItem') === 'series') {
              this.chapterList = chapList;
              this.comicService.cacheComic[`${this.currentUrl}/home`] = chapList;
            }
          }, err => {
            for (var i = 0; i < resp.data.data.length; i++) {
              if (this.subscribedComic(resp.data.data[i])) {
                this.chapterList.push(resp.data.data[i]);
              }
            }
          });
          this.action = resp.data.data[resp.data.data.length - 1] ? resp.data.data[resp.data.data.length - 1].storyID : null;
          this.isLoadingHeader = false;
          this.isLoadingHeader = false;
        }, err => {
          this.isLoadingHeader = false;
          this.showFeedStory = false;
          console.log(err)
        })
      } else {
        if (this.homeFeedsSubscription) {
          this.homeFeedsSubscription.unsubscribe();
        }
        this.feedsSubscription = this.feedService.getUserFeed(value).subscribe((resp) => {
          let chapsList = [];
          if (this.chapterList.length > 0) {
            chapsList = this.chapterList.slice();
          } else {
            const latestTitle = {
              title: "My Comics",
              titleOn: "Latest Comics"
            }
            chapsList.push(latestTitle);
          }
          if (resp && resp.data && resp.data.data.length == 0 && !this.isFollowComic) {
            this.isFollowComic = false;
            // if (this.isMobileView) {
            //   this.chapterList = [];
            //   this.chapterList.push({
            //     title: 'My Comics'
            //   })
            // }
          } else {
            this.isFollowComic = true;
            if (this.isMobileView && this.chapterList && this.chapterList.length) {
              // this.chapterList = this.chapterList.filter(c => !c.placeHolder);
              chapsList = chapsList.filter(c => !c.placeHolder);
            }
          }
          this.fetchDone = true;
          resp.data.data.map((element: { storyID: any; }) => {
            this.storyIds.push(element.storyID);
          });
          this.feedService.getAllStoryLikes(this.storyIds).then((res) => {
            this.allStoryLikes = res.data;
            this.showFeedStory = false;
          }, err => {
            this.fetchDone = true;
          }).then( async () => {
            for (var i = 0; i < resp.data.data.length; i++) {
              const comicData = resp.data.data[i];
              resp.data.data[i] = await this.appService.updateComicDataWithConfig(comicData);
              if (resp.data.data[i].updatedAt) {
                this.panelPublishDate = new Date(resp.data.data[i].updatedAt) <= new Date() ? true : false
              }
              if (this.subscribedComic(resp.data.data[i]) && this.panelPublishDate) {
                chapsList.push(resp.data.data[i]);
              }
            }
            for (var i = 0; i < chapsList.length; i++) {
              if (this.allStoryLikes && this.allStoryLikes.data && this.allStoryLikes.data.length) {
                for (var j = 0; j < this.allStoryLikes.data.length; j++) {
                  if (chapsList[i].storyID == this.allStoryLikes.data[j].storyId) {
                    chapsList[i].isLiked = this.allStoryLikes.data[j].isLiked;
                    chapsList[i].giftedItems = this.allStoryLikes.data[j].giftedItems;
                  }
                }
              }
            }

            if (this.localStorageService.getItem('selectedMenuItem') === 'following') {
              this.chapterList = chapsList;
              this.comicService.cacheComic[`${this.currentUrl}/following`] = chapsList;
            }
          }, err => {
            for (var i = 0; i < resp.data.data.length; i++) {
              if (this.subscribedComic(resp.data.data[i])) {
                this.chapterList.push(resp.data.data[i]);
              }
            }
          });
          this.action = resp.data.data[resp.data.data.length - 1] ? resp.data.data[resp.data.data.length - 1].myFeedID : null;
          this.isLoadingHeader = false;
        }, err => {
          this.isLoadingHeader = false;
          this.isFollowComic = true;
          this.fetchDone = true;
          this.showFeedStory = false;
        })
      }
    } else if (this.comicService.isCoverPage()) {
      // this.showFeedStory = true;
      const onlyUnread = this.comicSeriesViewTabs.unread;
      this.feedService.getSeriesPageFeeds(value, this.currentUrl, 20, onlyUnread).then((resp) => {
        // this.fetchDone = true;
        resp.data.data.map((element: { storyID: any; }) => {
          this.storyIds.push(element.storyID);
        })
        this.feedService.getAllStoryLikes(this.storyIds).then((res) => {
          this.allStoryLikes = res.data;
          // this.showFeedStory = false;
        }).then(async () => {
          const results = await this.getMonthWiseComics(resp.data.data);
          results.forEach(monthData => this.chapterList.push(monthData));
          this.showListViewGIF = false;
          this.isLoadingHeader = false;
        }, err => {
          this.showFeedStory = false;
          for (var i = 0; i < resp.data.data.length; i++) {
            if (this.subscribedComic(resp.data.data[i])) {
              this.chapterList.push(resp.data.data[i]);
            }
          }
        });
        this.action = resp.data.data[resp.data.data.length - 1] ? resp.data.data[resp.data.data.length - 1].storyID : null;
        this.currentUrl = this.comicService.getCurrentUrl(); // making sure to place right URL in currentUrl
        this.comicService.cacheComic[this.currentUrl] = this.chapterList;
      }, err => {
        this.showFeedStory = false;
        this.fetchDone = true;
      })
    }
  }
  
  async getMonthWiseComics(comicData: any[]) {
    const monthWiseComics: any[] = [];

    for (const comic of comicData) {
      // Prepare each comic item's data
      const updatedComic = await this.appService.updateComicDataWithConfig(comic);

      if (updatedComic.template === 'toc' && updatedComic.AppID) {
        updatedComic.template = 'list';
      }

      if (updatedComic.updatedAt && new Date(updatedComic.updatedAt) > new Date()) continue;

      const createdAtDate = new Date(updatedComic.createdAt);
      if (isNaN(createdAtDate.getTime())) {
        console.error(`Invalid date for comic: ${updatedComic.title} - createdAt: ${updatedComic.createdAt}`);
        continue; // Skip invalid dates
      }

      const monthKey = new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), 1); // Get the first day of the month
      const monthTimestamp = monthKey.getTime(); // Use timestamp to uniquely identify the month

      const lastRenderedMonth = this.chapterList && this.chapterList[this.chapterList.length - 1] && this.chapterList[this.chapterList.length - 1]['template'] === templateTypes.month && this.chapterList[this.chapterList.length - 1];
      const fromRenderedMonth = lastRenderedMonth && lastRenderedMonth.comicForMonth === monthTimestamp;
      if (fromRenderedMonth) {
        this.chapterList[this.chapterList.length - 1] && this.chapterList[this.chapterList.length - 1].comicsForCurrentMonth.push(updatedComic);
      } else {
        // Find if the month already exists in the result array
        const existingMonth = monthWiseComics.find(item => item.comicForMonth === monthTimestamp);

        if (existingMonth) {
          // If it exists, add the current comic to the existing month's array
          existingMonth.comicsForCurrentMonth.push(updatedComic);
        } else {
          // If it doesn't exist, create a new entry for that month
          monthWiseComics.push({
            comicForMonth: monthTimestamp,
            monthToDisplay: this.datePipe.transform(monthKey, 'MMMM yyyy'),
            comicsForCurrentMonth: [updatedComic], // Initialize with the current comic
            template: templateTypes.month
          });
        }
      }
    }

    return monthWiseComics;
  }

  public async showMetaTags(resp) {
    this.homeRoute = resp.comics.home;
    this.comicType = undefined;
    this.titleService.setTitle(this.resp.comics.title);
    this.hideOpenInApp = this.resp && this.resp.comics && this.resp.comics.hideOpenInApp;
    this.metaDescription = this.resp.comics.comments ? this.resp.comics.comments.replace(/(<([^>]+)>)/ig, "") : this.resp.comics.description.replace(/(<([^>]+)>)/ig, "");
    if (resp.comics.ogImage) {
      let img = this.comicService.resolvePath(this.currentUrl, this.resp.comics.ogImage);
      img = img ? img.replace('//', '/') : img;
      this.imgPath = `${COMIC_IMG_API}${img}`;
    } else {
      this.imgPath = 'https://storage.googleapis.com/tinyview-d78fb.appspot.com/tinyview/tinyview-fb-cover.jpg';
    }

    this.pageMetaService.setMetaTags(this.documentService, this.titleService, this.metaService);
    // const metaData = [{
    //   property: 'twitter:description',
    //   content: this.metaDescription
    // },
    // {
    //   property: 'twitter:image',
    //   content: `${this.imgPath}`
    // },
    // {
    //   property: 'twitter:title',
    //   content: resp.comics.title
    // },
    // {
    //   property: 'og:title',
    //   content: resp.comics.title
    // },
    // {
    //   property: 'og:description',
    //   content: this.metaDescription
    // },
    // {
    //   property: 'og:image',
    //   content: `${this.imgPath}`
    // },
    // {
    //   property: 'og:url',
    //   content: `https://tinyview.com${this.currentUrl}`
    // }];
    // for (const metaProperty of metaData) {
    //   this.meta.updateTag({
    //     property: metaProperty.property,
    //     content: metaProperty.content
    //   });
    // }

    // isPaid means comic is premium or not
    if (this.resp && this.resp.comics) {
      if (isPlatformBrowser(this.platform)) {
        var prodIds = this.localStorageService.getItem('productID');
        // Ensure 'show-to' is an object with a 'key' property, if not then isPaid should be false
        if (!this.resp.comics['show-to']) {
          this.isPaid = false;
        } else if ((this.resp.comics['show-to'] && this.resp.comics['show-to'].key === 'everyone') &&
          (this.resp.comics.influencePrice === null || this.resp.comics.influencePrice === undefined ||
            this.resp.comics.influencePrice === 0)) {
          this.isPaid = false;
        } else {
          if (this.isPaid == undefined || this.isPaid == false) {
            this.isPaid = true;
          }
          if (((this.resp.comics['show-to'] && this.resp.comics['show-to'].key === 'subscribers-only')) && (prodIds != null) && (prodIds.length > 0)) {
            this.isPaid = false;
          } else {
            this.isPaid = true;
            this.comicType = 'Premium Comic';
          }
        }
      }
      this.errorMsg = null;
      //TODO: add logic to show already unlocked comics. i.e. isPaid set to false
    }
  }

  // switch buttom for showing all the comics
  public async showComics() {
    this.showAllComics = !this.showAllComics;
    const updateUserSettings = await this.apiService.send(API_KEYS.UPDATE_USER_SETTINGS); // MultiPageInfo API
    updateUserSettings({
      "showAllComics": this.showAllComics
    }).then(() => {
      if (isPlatformBrowser(this.platform)) {
        this.localStorageService.setItem('showAllComics', JSON.stringify(this.showAllComics));
        location.reload();
      }
    })
  }

  public subscribedComic(imageData): boolean {
    if (isPlatformBrowser(this.platform)) {
      this.prodID = this.localStorageService.getItem('productID');
      if (imageData && imageData.inAppProducts) {
        for (const key in imageData.inAppProducts) {
          const reqObject = imageData.inAppProducts[key];
          const requirePurchaseKey = Object.keys(reqObject)[0];
          const needsPurchase = reqObject[requirePurchaseKey];
          const productPurchased = (this.prodID || []).includes(requirePurchaseKey);
          if (needsPurchase && productPurchased) {
            return true;
          } else if (!needsPurchase && !productPurchased) {
            return true;
          } else if (!needsPurchase && productPurchased) {
            return false;
          }
        }
      } else {
        return true;
      }
    }
  }

  public doLikefromComicPage(data: any) {
    // like a comic from comic page....
    if (isPlatformBrowser(this.platform)) {
      let series = this.currentUrl;
      if (this.comicService.cacheComic[series]) {
        this.cachedChapterList = this.comicService.cacheComic[series];
      } else {
        this.cachedChapterList = this.comicService.cacheComic[''];
      }
    }
    let userData = data;
    if (!userData.liked) {
      this.likeCount--;
      this.isLiked2 = false;
      this.giftsCounts.LIKE--;
      this.feedService.doDislikeStory(userData.storyID);
    } else {
      this.likeCount++;
      this.isLiked2 = true;
      this.giftsCounts.LIKE++;
      this.feedService.doLikeStory(userData.storyID)
    }
  }

  openFollowModal() {
    if (this.modalRef) this.modalRef.hide();

    const series = {}
    if (this.tinyviewPage) {
      series['action'] = `/index.json`;
      series['title'] = `Tinyview`;
    }

    const initialState = {
      tinyviewPage: this.tinyviewPage,
      isFollowingAlready: this.followBtnText === 'Following',
      series: this.tinyviewPage ? series : this.currentSeriesDetails,
      hasBonusPanel: this.hasBonusPanel
    };
    this.modalRef = this.modalService.show(FollowModalComponent, Object.assign({}, {
      initialState,
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
    this.showLoader = false;
    this.newComicService.setFollowLoaderState({isLoading: false, isFollowing: this.followBtnText === 'Following'});
  }

  // Not used now
  public async doFollowForComicWriters() {
    this.showLoader = true;
    this.comicService.cacheComic[""] = null
    if (this.param) {
      let getStory = this.storyPageActionURLHandling();

      getStory.then(async resp => {
        this.param = this.storyID = resp.data.data.storyID;
        if (isPlatformBrowser(this.platform)) {
          let action = resp.data.data.user.action;
          let isFollowing = this.followBtnText === 'Follow';

          const followResult = await this.comicService.addRemoveLike(action, isFollowing, false, {showToast: !this.hasBonusPanel || this.isSubscriber, series: this.currentSeriesDetails});
          if (followResult.success) {
            this.followBtnText = isFollowing ? 'Following' : 'Follow';
            if ((this.followBtnText === 'Following' && this.hasBonusPanel) && !this.isSubscriber)this.toastr.success(`Thanks for following ${this.currentSeriesDetails.title}! We have unlocked bonus panels of ${this.currentSeriesDetails.title} for you.`);
            if (this.hasBonusPanel || this.hasFollowBtn) {
              setTimeout( () => {
                window.location.reload();
              }, 3000);
            }
            this.showLoader = false;
          } else if (followResult.openFollowModal) {
            this.openFollowModal();
          }
        }
      })
    }
    if (isPlatformBrowser(this.platform) && !this.param) {
      this.Url = this.comicService.formatSeriesName(this.currentUrl).replace('/footer.json', '');
      const isFollowAction = this.followBtnText === 'Follow';
      const likeUrl = `${this.Url}/index.json`;
      // const showToast = !this.isComicPage; 
      const followResult = await this.comicService.addRemoveLike(likeUrl, isFollowAction, false, { showToast: !this.hasBonusPanel || this.isSubscriber, series: this.currentSeriesDetails});
      if (followResult.success) {
        this.followBtnText = isFollowAction ? 'Following' : 'Follow';
        this.showLoader = false;
        if ((this.followBtnText === 'Following' && this.hasBonusPanel) && !this.isSubscriber)this.toastr.success(`Thanks for following ${this.currentSeriesDetails.title}! We have unlocked bonus panels of ${this.currentSeriesDetails.title} for you.`);
        if ((this.hasBonusPanel || this.hasFollowBtn)) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else if (followResult.openFollowModal) {
        this.openFollowModal();
      }
    }
  }

  public async addRemoveLike() {
    // follow from series page ...
    this.showLoader = true;
    this.newComicService.setFollowLoaderState({isLoading: true, isFollowing: this.followBtnText === 'Following'});

    this.comicService.cacheComic[""] = null;
    if (isPlatformBrowser(this.platform)) {
      const isFollowAction = this.followBtnText === 'Follow';
      const baseUrl = this.comicService.formatSeriesName(this.currentUrl).replace('/footer.json', '');
      const likeUrl = ['','/'].includes(this.currentUrl) ? `/tinyview/index.json` : `${baseUrl}/index.json`;

      const followResult = await this.comicService.addRemoveLike(likeUrl, isFollowAction, false, {showToast: true, series: this.currentSeriesDetails});
      if (followResult.success) {
        if (isFollowAction) {
          this.likeCount += 1;
          this.followBtnText = 'Following';
          this.isLiked = true;
        } else {
          this.likeCount -= 1;
          this.followBtnText = 'Follow';
          this.isLiked = false;
        }
        this.showLoader = false;
        this.newComicService.setFollowLoaderState({isLoading: false, isFollowing: this.followBtnText === 'Following'});
      } else if (followResult.openFollowModal) {
        this.openFollowModal();
      }
      if (this.isSeriesPage) {
        this.getNavigationData();
      }
    }
  }

  public openApp(): void {
    this.comicService.openInStore(this.param || '');
  }

  public closeOpenInApp() {
    this.openAppModalRef.hide();
  }

  onManageAlertClick() {
    this.modalRef.hide();
    this.router.navigate(['manage-alerts']);
  }

  openModal(template: TemplateRef<any>) {
    if (this.modalRef) this.modalRef.hide();
    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'text-app-modal',
      ignoreBackdropClick: true
    }));
  }

  openActionModal(template: TemplateRef<any>, isLike: any) {
    this.likedComic = isLike;
    this.actionModalRef = this.modalService.show(template, Object.assign({}, {
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
  }

  // Dupe of comic component
  public sendSMS() {
    this.smsResponse = null;
    this.willShowLoader = true;
    const phone = this.myform.value.phone;
    let tags = this.comicService.isCoverPage(true);
    if (tags == '') {
      tags = 'tinyview';
    }
    const linkData = {
      campaign: 'website',
      feature: 'open-in-app',
      channel: 'tinyview',
      tags: [tags],
      data: this.comicService.getBranchLinkData()
    };
    const linkOptions = {
      make_new_link: false
    };
    const callback = (err: any, result: any) => {
      if (err) {
        this.willShowLoader = false;
        this.smsResponse = 'Sorry, an error occurred. Please try again later.';
      } else {
        this.willShowLoader = false;
        this.smsResponse = 'Link sent successfully.';
      }
    };
    if (window['branch']) {
      window['branch'].sendSMS(phone.internationalNumber, linkData, linkOptions, callback);
    }
  }

  onShare() {
    let url;
    if (this.isValidUrl(this.currentUrl) && this.currentUrl && (this.currentUrl && this.currentUrl.includes('https') || this.currentUrl.includes('http') || this.currentUrl.includes('https://storage.googleapis.com/'))) {
      url = this.currentUrl;
    } else {
      url = 'https://tinyview.com' + this.currentUrl.replace('/index.json', '')
    }
    this.shareData = {
      title: 'Tinyview',
      text: this.comicService.toTitleFormat(/[^/]*$/.exec(this.currentUrl)[0]),
      url: url,
    };
    if (this.currentDevice == 'androidBrowser' || this.currentDevice == 'iosBrowser') {
      this.share();
    } else {
      this.showShare = !this.showShare;
    }
  }

  // dupe
  public isValidUrl = urlString => {
    // SSR check
    if (typeof window === "undefined") {
      return '';
    }
    var a = document.createElement('a');
    a.href = urlString;
    return (a.host && a.host != window.location.host);
  }

  public getFirstLetters(str) {
    const firstLetters = this.comicService.getFirstLetters(str)
    return firstLetters;
  }

  async share() {
    let newVariable: any;
    if (isPlatformBrowser(this.platform) && window && window.navigator) {
      newVariable = window.navigator;
      if (newVariable && newVariable.share) {
        try {
          await newVariable.share(this.shareData);
        } catch (err) {
        }
      }
    }
  }

  public shareFacebook(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(this.shareData.url);
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&utmSource=facebook&utmContent=referral&utmTerm=topbar&referrer=facebook`;
      this.comicService.popUpUrl(shareUrl);
    }
  }

  public shareTwitter(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(this.shareData.url);
      const shareUrl = `https://www.twitter.com/intent/tweet?url=${url}&via=TinyviewComics&utmSource=twitter&utmContent=referral&utmTerm=topbar&referrer=twitter`;
      this.comicService.popUpUrl(shareUrl);
    }
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.feedsSubscription) {
      this.feedsSubscription.unsubscribe();
    }
    if (this.homeFeedsSubscription) {
      this.homeFeedsSubscription.unsubscribe();
    }
    if (this.urlSubscribe) {
      this.urlSubscribe.unsubscribe();
    }
    if (this.subscriptions.followSubscription) {
      this.subscriptions.followSubscription.unsubscribe();
    }
    if (this.subscriptions.openShareModalSubscription) {
      this.subscriptions.openShareModalSubscription.unsubscribe();
    }
  }

  public openInStore() {
    let tags = this.comicService.isCoverPage(true);
    if (tags == '') {
      tags = 'tinyview';
    }
    const linkData = {
      campaign: 'website',
      feature: 'open-in-app',
      channel: 'tinyview',
      tags: [tags],
      data: this.getBranchLinkData()
    };
    if (isPlatformBrowser(this.platform) && window && window['branch']) {
      window['branch'].link(linkData, (err, link) => {
        location.href = link;
      });
    }
  }

  // public sendMessage(linkData, linkOptions, phone) {
  //   const callback = (err, result) => {
  //     if (err) {
  //       alert('Something went wrong.');
  //     } else {
  //       alert('Text message was sent successfully.');
  //     }
  //   };
  //   if (isPlatformBrowser(this.platform) && window && window['branch']) {
  //     window['branch'].sendSMS(phone, linkData, linkOptions, callback);
  //   }
  // }

  // Dupe of comic service
  public getBranchLinkData(): any {
    const COMIC_API = `${environment.API_END_POINT}`;
    const switch_app_link = `${environment.SWITCH_APP_LINK}`
    // const referredByCookie = this.cookieService.get('referred_by');
    if (isPlatformBrowser(this.platform)) {
      const referredByCookie = this.localStorageService.getItem('referred_by');
      let currentUrl = this.comicService.getCurrentUrl();
      if (currentUrl == '/') {
        currentUrl = ''
      }
      return {
        referred_by: referredByCookie,
        deeplink_path: `${COMIC_API}${currentUrl}/index.json`,
        $ios_url: 'https://apps.apple.com/us/app/tinyview-comics/id1478702420',
        $android_url: `https://play.google.com/store/apps/details?id=${switch_app_link}`
      };
    }
  }

  openAppModal(template: TemplateRef<any>) {
    this.openAppModalRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-sm",
      // class: 'modal-xl',
      ignoreBackdropClick: true
    }));
  }
  async unlockComic(flag?: string) {
    if (!this.influencePoints.balance || flag === 'earn') {
      this.router.navigate([INFLUENCE_PAGE.url])
      return
    }
    this.isComicUnlocking = true;
    const res = await this.comicService.unlockComic(`${this.currentUrl}/index.json`);
    const urls = this.sessionStorageService.getItem('unlockedURLs') || [];
    urls.push(this.currentUrl);
    this.sessionStorageService.setItem('unlockedURLs', urls);
    this.sessionStorageService.setItem('showUnlockedToaster', this.currentUrl);
    this.isComicUnlocking = false;
    window.location.reload();
  }

  async openShareModal(template: TemplateRef<any>) {
    this.showMetaTags(this.resp);
    const data = await this.comicService.getInfluenceData(this.sharingStoryAction, this.sharingStoryID, this.sharingStoryIsWebsite);
    // console.log('storyID', this.sharingStoryID);
    // console.log('actionURL', this.sharingStoryAction);
    // console.log('data', data);
    window['branch'].link(data, (err, link) => {
      this.shareLink = link;
      const isMobile = [BROWSER.IOS, BROWSER.ANDROID].includes(this.currentDevice) ? ' mobile-modal' : '';
      this.shareRef = this.modalService.show(template, Object.assign({}, {
        class: `modal-md${isMobile}`,
        ignoreBackdropClick: false,
        animation: !isMobile
      }));

      // Listening to modal close event via modalService
      // this.modalService.onHide.subscribe((reason: string | any) => {
      //   this.shareModalAlreadyOpened = false;
      // });
    });
  }

  public manualShare(share) {
    this.comicService.shareConditions(this.shareLink, share.action, this.isDesktop, this.isAndroid);
    // this.comicService.manualShare(this.storyAction, this.storyID, share, this.isDesktop, this.isAndroid);
    this.shareRef.hide();
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobileView ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  private convertTemplateToctoList(): void {
    this.chapterList.forEach(chapter => {
      if (chapter.template === 'toc' && chapter.AppID) {
        chapter.template = 'list';
      }
    });
  };

  private convertTemplateListtoToc(): void {
    this.chapterList.forEach(chapter => {
      if (chapter.template === 'list' && chapter.AppID) {
        chapter.template = 'toc';
      }
    });
  }

  showAll() {
    if (this.comicSeriesViewTabs.all) return;
    // this.convertTemplateToctoList();
    this.comicSeriesViewTabs.all = true;
    this.comicSeriesViewTabs.unread = false;
    const serializedTabs = JSON.stringify(this.comicSeriesViewTabs);
    // Store the tab-info in session storage
    this.sessionStorageService.setItem('comicSeriesViewTabs', serializedTabs);
    this.chapterList = this.chapterList.filter( item => item.template !== templateTypes.month);
    this.showListViewGIF = true;
    this.getFeedsOfStories(null);
  }

  showUnread() {
    if (this.comicSeriesViewTabs.unread) return;
    // this.convertTemplateListtoToc();
    this.comicSeriesViewTabs.all = false;
    this.comicSeriesViewTabs.unread = true;
    const serializedTabs = JSON.stringify(this.comicSeriesViewTabs);
    // Store the tab-info in session storage
    this.sessionStorageService.setItem('comicSeriesViewTabs', serializedTabs);
    this.chapterList = this.chapterList.filter( item => item.template !== templateTypes.month);
    this.showListViewGIF = true;
    this.getFeedsOfStories(null);
  }

  setViewTypeOnSeriesPage() {
    const comicSeriesViewTabsString = this.sessionStorageService.getItem('comicSeriesViewTabs');
    let comicSeriesViewTabs = null;
  
    try {
      // Check if `comicSeriesViewTabsString` is already an object with expected properties
      if (typeof comicSeriesViewTabsString === 'object' && (comicSeriesViewTabsString.all || comicSeriesViewTabsString.unread)) {
        this.comicSeriesViewTabs = comicSeriesViewTabsString;
      } else {
        // Attempt to parse as JSON if not an object
        comicSeriesViewTabs = JSON.parse(comicSeriesViewTabsString);
        // Check if parsed JSON has expected properties
        if (comicSeriesViewTabs.all || comicSeriesViewTabs.unread) {
          this.comicSeriesViewTabs = comicSeriesViewTabs;
        } else {
          this.setDefaultTabs();
        }
      }
    } catch (e) {
      // Set default tabs if parsing fails
      this.setDefaultTabs();
    }
  }
  
  setDefaultTabs() {
    this.comicSeriesViewTabs.all = true;
    this.comicSeriesViewTabs.unread = false;
    const serializedTabs = JSON.stringify(this.comicSeriesViewTabs);
    // Store the tab-info in session storage
    this.sessionStorageService.setItem('comicSeriesViewTabs', serializedTabs);
  }
  // public setComicNavPanel() {
  //   // adding comic navigation panel above subscribe and influence point panels on comic page only
  //   const isComicNavPanel = this.chapterList.find(panel => panel.action === 'comicNavigation');
  //   if (!isComicNavPanel) {
  //     this.chapterList.push({ action: 'comicNavigation' });
  //   }
  // }

  public onBannerChange(data) {
    if (!data) return;
    this.likeCount += data.likeCount;
    this.followBtnText = data.followBtnText;
  }

  public showRead(event) {
    this.showDoubleTick = event.show;
  }

  public showAllComments(storyID, signinModal, commentModal, redirect) {
    this.ctaList = this.comicService.getCTAList();
    if (this.ctaList.length && !redirect) {
      if (this.ctaList.includes('signin')) this.openModal(signinModal);
      if (this.ctaList.includes('incompleteProfile')) this.openCommentModal(commentModal);
      if (this.ctaList.includes('subscribe')) this.openModal(signinModal);
      return;
    }
    this.comicService.getUserDetails().then(res => {
      const userDetails = res.data;
      if (this.anonymousUser || this.ctaList.includes('incompleteProfile')) {
        var sp = window.scrollY;
        this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
        this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
        this.router.navigate(['story', storyID]);
      } else if (userDetails.userName && userDetails.gender) {
        if (isPlatformBrowser(this.platform)) {
          var sp = window.scrollY;
          this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
          this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
        }
        this.router.navigate(['story', storyID]);
      }
    });
  }

  openCommentModal(template: TemplateRef<any>) {
    this.commentModalRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-layout",
      // class: 'modal-xl',
      ignoreBackdropClick: true
    }));
  }

  public goToEditProfilePage() {
    this.router.navigate(['/edit-profile']).then(() => {
      this.commentModalRef.hide();
    })
  }
}
