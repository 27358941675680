import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NewComicService } from 'src/app/new-comic.service';
import { ComicService } from 'src/app/services/comic.service';
import { MenuComponent } from 'src/app/menu/menu.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isAnonymousUser } from 'src/app/utilities/common.util';
import { NotificationService } from 'src/app/services/notification.service';
import { TINYVIEW_SHOP } from 'src/app/constants/common.constants';

@Component({
  selector: 'app-bottom-nav-mobile',
  templateUrl: './bottom-nav-mobile.component.html',
  styleUrls: ['./bottom-nav-mobile.component.scss', './../../pages/comic-wrapper/comic-wrapper.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'

      })),
      state('out', style({
        transform: 'translate3d(100%,0,0)',
        'display': 'none'
      })),
      transition('in => out', animate('1ms ease-in-out')),
      transition('out => in', animate('1ms ease-in-out'))
    ]),
  ]
})
export class BottomNavMobileComponent implements OnInit {
  public currentURL: string;
  public activeIcon: string;

  /// old Slider
  @ViewChild(MenuComponent, { static: false }) chviewChild: MenuComponent;
  public menuState: string = 'out';
  public name: string;
  public clickedOutside: boolean = true;
  userImage: any;
  userName: any;
  userBadges: any;
  userPhoneNumber: any;
  isSubscribed: boolean;
  manageSubscriptionObj: any;
  public previousItem: string;

  public disableBtn = {
    prevComic: true,
    nextComic: true,
  };

  public btnNavigation = {
    prevComic: '',
    nextComic: ''
  };

  iconUrls: { [key: string]: string } = {
    directory: '../../../assets/images/new-icons/icons8-signpost-100.png',
    shop: '../../../assets/images/new-icons/icons8-shopping-bag-100-2.png',
    tickbox: '../../../assets/images/new-icons/tick-box-100.png', // removed and placed above the bottom bar
    home: '../../../assets/images/new-icons/icons8-home-100.png',
    user: '../../../assets/images/new-icons/icons8-user-menu-male-100.png',
    notification: '../../../assets/images/new-icons/icons8-alarm-100.png',
    share: '../../../assets/images/new-icons/icons8-upload-100.png',
    back: '../../../assets/images/new-icons/icons8-back-100.png',
    'previous-comic': '../../../assets/images/new-icons/icons8-rewind-100.png',
    'next-comic': '../../../assets/images/new-icons/icons8-play-100.png',
    'series-home': '../../../assets/images/new-icons/icons8-list-100.png',
  };
  iconUrlsDark: { [key: string]: string } = {
    directory: '../../../assets/images/new-icons/icons8-signpost-100-2.png',
    shop: '../../../assets/images/new-icons/icons8-shopping-bag-100.png',
    tickbox: '../../../assets/images/new-icons/tick-box-100-2.png', // removed and placed above the bottom bar
    home: '../../../assets/images/new-icons/icons8-home-100-2.png',
    user: '../../../assets/images/new-icons/user-menu-male-100-2.png',
    notification: '../../../assets/images/new-icons/icons8-alarm-100-2.png',
    'previous-comic': '../../../assets/images/new-icons/icons8-rewind-100-626262.png',
    'next-comic': '../../../assets/images/new-icons/icons8-play-100-626262.png',
    'series-home': '../../../assets/images/new-icons/icons8-list-100-626262.png',
  };
  isMobile: boolean;
  inviteFriendsRef: any;
  isAnonymous: any;
  isIncompleteProfile: boolean;
  unreadCount: string;
  isSeriesPage: boolean;
  isComicPage: boolean;
  isStoryPage: boolean;
  isUserFollowingCurrentSeries: boolean;
  userDetailsLoading: boolean;
  continueReadingData: any;
  followBtnLoading: boolean;
  seriesEpisodes: any;
  currentComicAction: any;
  seriesHome: any;
  isExternalLink: boolean;
  externalBtnCta: string;
  isHomePage: boolean;
  episodeBtnCta: string;
  subDetails: any;

  constructor(
    private router: Router,
    private location: Location,
    private newComicService: NewComicService,
    private localStorageService: LocalStorageService,
    private modalService: BsModalService,
    private comicService: ComicService,
    private readonly route: ActivatedRoute,
    private notificationService: NotificationService
  ) {
    this.checkAndUpdatePageStatus();
  }

  ngOnInit() {
    this.isAnonymous = isAnonymousUser();
    this.isMobile = this.newComicService.isMobileView();
    this.currentURL = this.comicService.getCurrentUrl();
    this.isStoryPage = this.newComicService.isStoryPage();

    this.activeIcon =
      (this.location.path() === '' &&
        this.localStorageService.getItem('selectedMenuItem') === 'following')
        ? '/following'
        : this.location.path();
    this.getUserDetails();
    this.newComicService.getsidebarItemSelected().subscribe((val) => {
      if (val && !val.sameItemClicked) {
        if (val && val.menuItem === 'home') {
          this.activeIcon = '';
          this.localStorageService.setItem('selectedMenuItem', 'home');
          this.localStorageService.setItem('isComingFromFollowing', false);
        } else if (val && val.menuItem === 'following') {
          this.activeIcon = '/following';
          this.localStorageService.setItem('selectedMenuItem', 'following');
          this.localStorageService.setItem('isComingFromFollowing', true);
        }
      }
    }, err => {
      this.activeIcon = '';
    })

    // refresh login data on successful signin
    this.newComicService.getRefreshLoginDataEvent().subscribe(() => {
      this.getUserDetails();
    });

    // code to handle same menu click for home and following page for smart scrolling purpose
    if (!this.previousItem) {
      if (['', '/'].includes(this.activeIcon)) {
        this.previousItem = 'home';
      }
      if (this.activeIcon === '/following') {
        this.previousItem = 'tickbox';
      }
      if (this.activeIcon === 'directory') {
        this.previousItem = 'directory';
      }
    }
    this.setUnseenNotificationCount();

    this.subjectHandlers();
  }

  private setUnseenNotificationCount() {
    this.notificationService.getNotification(1, '', true).then(res => {
      if (this.activeIcon !== '/notifications' && res.unReadCount > 0) {
        this.unreadCount = Number(res.unReadCount) > 99 ? `99+` : String(res.unReadCount);
      } else {
        this.unreadCount = '0';
        this.notificationService.updateLastSeenNotificationAt(Date.now());
      }
    });
  }

  public getUserDetails() {
    this.userDetailsLoading = true;
    this.comicService.getUserDetails().then(res => {
      if (this.isSeriesPage || this.isComicPage) { // to show/hide "+ Follow" button on bottom nav
        const currentSeries = this.getSeriesNameFromURL(window.location.pathname);
        this.isUserFollowingCurrentSeries = !!res.data.alerts[currentSeries];
      }
      this.isIncompleteProfile = !this.isAnonymous && (!res.data.displayName || !res.data.gender);
      this.setUserDetails(res);
      this.userDetailsLoading = false;
    }, () => {
      this.userDetailsLoading = false;
    });
  }

  public navigateTo(icon: string, event?: any): void {
    event && event.preventDefault(); // to stop it from reloading the page as anchor tag does this sometimes
    // code to handle same menu click for home and following page for smart scrolling purpose
    const sameItemClicked = this.previousItem === icon;
    if (sameItemClicked && !['tickbox', 'home', 'directory', 'back', 'share', 'open-link', 'next-comic', 'previous-comic', 'view-comic', 'series-home', 'follow', 'shop'].includes(icon)) {
      return;
    }

    if (!['shop'].includes(icon)) {
      this.activeIcon = icon;
    }
    // For navigation
    switch (icon) {
      case 'home':
        if (this.isStoryPage || this.isComicPage || this.isSeriesPage) {
          // Navigating to following page, if he is coming from following page
          const isComingFromFollowing = this.localStorageService.getItem('isComingFromFollowing');
          if (isComingFromFollowing) {
            this.newComicService.setsidebarItemSelected({ menuItem: 'following', sameItemClicked: sameItemClicked });
            this.activeIcon = '/following';
            if (sameItemClicked) return; // stopping navigation if same following is clicked
            this.router.navigate(['']);
            break;
          }
        }

        this.newComicService.setsidebarItemSelected({ menuItem: 'home', sameItemClicked: sameItemClicked });
        this.activeIcon = '';
        if (sameItemClicked) return; // stopping navigation if same home is clicked
        this.router.navigate(['']);
        break;
      case 'directory':
        this.newComicService.setsidebarItemSelected({ menuItem: 'directory', sameItemClicked: sameItemClicked });
        this.activeIcon = 'directory';
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.router.navigate(['/tinyview/comic-series-directory']);
        break;
      case 'tickbox':
        this.newComicService.setsidebarItemSelected({ menuItem: 'following', sameItemClicked: sameItemClicked });
        this.activeIcon = '/following';
        if (sameItemClicked) return; // stopping navigation if same following is clicked
        this.router.navigate(['']);
        break;
      case 'notification':
        // Call API: updateLastSeenNotificationAt
        this.notificationService.updateLastSeenNotificationAt(Date.now());
        this.router.navigate(['/notifications']);
        break;
      case 'back':
        this.goBack();
        break;
      case 'series-home':
        let seriesName = this.seriesHome;
        if (this.isComicPage) {
          seriesName = this.getSeriesNameFromURL(window.location.pathname);
        }
        this.router.navigate([seriesName]);
        break;
      case 'view-comic':
        this.comicNavigation(this.currentComicAction);
        break;
      case 'open-link':
        this.openLinkInNewTab(this.currentComicAction);
        break;
      case 'next-comic':
        this.comicNavigation(this.btnNavigation.nextComic);
        break;
      case 'previous-comic':
        this.comicNavigation(this.btnNavigation.prevComic);
        break;
      case 'share':
        this.newComicService.triggerShareModal();
        break;
      case 'follow':
        this.newComicService.triggerFollowSeriesSubject();
        break;
      case 'shop':
        this.openLinkInNewTab(TINYVIEW_SHOP);
        break;
      default:
        this.newComicService.setsidebarItemSelected('home');
        this.router.navigate(['']);
        break;
    }

    this.previousItem = icon;
  }

  public getIconUrl(icon: string): string {
    switch (icon) {
      case 'directory':
      case '/tinyview/comic-series-directory':
        return ([icon, '/tinyview/comic-series-directory'].includes(this.activeIcon)) ? this.iconUrlsDark['directory'] : this.iconUrls['directory']
        break;
      case '/following':
        return this.activeIcon === icon ? this.iconUrlsDark['tickbox'] : this.iconUrls['tickbox']
        break;
      case '/account':
        return this.activeIcon === icon ? this.iconUrlsDark['user'] : this.iconUrls['user']
        break;
      case '/notifications':
        return this.activeIcon === icon ? this.iconUrlsDark['notification'] : this.iconUrls['notification']
        break;
      case 'back':
        return this.iconUrls['back'];
        break;
      case 'share':
        return this.iconUrls['share'];
        break;
      case 'previous-comic':
        return (this.disableBtn.prevComic) ? this.iconUrlsDark['previous-comic'] : this.iconUrls['previous-comic'];
        break;
      case 'next-comic':
        return (this.disableBtn.nextComic) ? this.iconUrlsDark['next-comic'] : this.iconUrls['next-comic'];
        break;
      case 'series-home':
        return this.iconUrls['series-home'];
        break;
      case 'shop':
        return this.iconUrls['shop'];
        break;
      default:
        return this.activeIcon === '' ? this.iconUrlsDark['home'] : this.iconUrls['home']
        break;
    }
  }

  ///  For Old slider
  public outsideClick(hasClickedOutside) {
    if (this.menuState == 'in') {
      this.name = hasClickedOutside ? 'clicked inside' : 'clicked outside';
      if (this.name == 'clicked outside') {
        this.menuState = 'out'
      }
    }
  }

  private setUserDetails(user) {
    this.userImage = user.data.photoURL;
    this.userName = user.data.displayName;
    this.userBadges = user.data.badges;
    this.userPhoneNumber = user.data.phoneNumber ? user.data.phoneNumber.substr(user.data.phoneNumber.length - 4) : null;
    this.subDetails = user.data.subscriptions.length && user.data.subscriptions[0];
    this.isSubscribed = this.subDetails ? true : false;
  }

  public toggleMenu() {
    this.menuState = this.menuState == 'out' ? 'in' : 'out';
    this.clickedOutside = false;
  }

  public parentCall() {
    this.chviewChild.ngOnInit();
  }

  public openInviteFriendsModal(template: TemplateRef<any>) {
    const isMobile = this.isMobile && !this.isAnonymous ? ' mobile-modal' : '';
    this.inviteFriendsRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: false,
      animation: !isMobile
    }));
  }

  public closeInviteFriendsModal() {
    this.inviteFriendsRef.hide();
  }

  public goBack(): void {
    // 3 for mobile and 2 for desktop
    if ((window.history.length <= 3) || this.route.snapshot.queryParams.showSubscriptionMsg) {
      // If history length is 3 or less, navigate to home page
      this.router.navigate(['/']);
    } else {
      // Otherwise, go back
      this.location.back();
    }
  }

  // Do not use this function on story page, it is only meant for series and comic pages
  public getSeriesNameFromURL(url) {
    const pathName = window.location.pathname;  // Gets the current URL path, e.g., "/the-other-end/2024/07/11/a-brief-history-of-onions"
    return pathName.split('/')[1];  // Splits the path by '/' and extracts the second part (index 1), which is "the-other-end"
  }

  public subjectHandlers() {
    // Subscribe to data changes
    if (this.isSeriesPage) {
      this.newComicService.continueReadingData$.subscribe(data => {
        this.continueReadingData = data;
        if (this.continueReadingData && this.continueReadingData.isFirstEpisode) {
          this.episodeBtnCta = 'First Episode';
        } else {
          this.episodeBtnCta = 'Next Episode';
        }
      });
    }

    this.newComicService.followLoader$.subscribe(data => {
      if (data) {
        this.followBtnLoading = data.isLoading;
        this.isUserFollowingCurrentSeries = data.isFollowing;
      }
    });

    this.newComicService.episodesList$.subscribe(data => {
      this.seriesEpisodes = data.episodes;

      if (this.isStoryPage) {
        this.currentComicAction = data.currentComicAction;
        this.isExternalLink = this.newComicService.isExternalImage(this.currentComicAction);
        this.seriesHome = data.seriesHome;
      }

      if (this.seriesEpisodes && this.seriesEpisodes.length && (this.isComicPage || this.isStoryPage)) {
        this.setPreviousAndNextComic();
      }

      // for story page only
      if (this.isExternalLink) {
        try {
          const domain = new URL(this.currentComicAction) && new URL(this.currentComicAction).hostname && new URL(this.currentComicAction).hostname.replace(/^www\./, '');
          this.externalBtnCta = this.comicService.isVideoWebsiteDomain(domain || this.currentComicAction);
        } catch (e) {
          this.externalBtnCta = 'Open Link';
        }
      }
    });
  }

  public navigateToNextComic(continueReadingData: any) {
    if (continueReadingData.actionType != "website") {
      this.router.navigate([continueReadingData.action.replace('index.json', '')])
    } else {
      this.openLinkInNewTab(continueReadingData.action);
    }
  }

  setPreviousAndNextComic() {
    const urlToMatch = this.isStoryPage ? this.currentComicAction : this.currentURL;
    const index = this.seriesEpisodes.findIndex(episodes => episodes.action.indexOf(urlToMatch) > -1);

    // Previous Comic
    this.disableBtn.prevComic = (index > -1) ? !!!this.seriesEpisodes[index - 1] : true;
    this.btnNavigation.prevComic = !this.disableBtn.prevComic ? this.seriesEpisodes[index - 1].action : '';

    // Next Comic
    this.disableBtn.nextComic = (index > -1) ? !!!this.seriesEpisodes[index + 1] : true;
    this.btnNavigation.nextComic = !this.disableBtn.nextComic ? this.seriesEpisodes[index + 1].action : '';
  }


  public comicNavigation(actionURL: string) {
    if (!actionURL) return;

    if (this.newComicService.isExternalImage(actionURL)) {
      this.router.navigate(['/story/action'], { queryParams: { actionURL } });
    } else {
      actionURL = actionURL.replace('/index.json', '');
      this.router.navigate([actionURL]);
    }
  }

  checkAndUpdatePageStatus() {
    // Immediately update the values the first time
    this.isSeriesPage = this.comicService.isSeriesPage() || this.comicService.isTinyviewSeriesPage();
    this.isComicPage = this.comicService.isComicPage() && !this.newComicService.isInfluencePointsPage() && !this.newComicService.isSubscribePage() && !this.newComicService.isSignUpNewPage() && !this.newComicService.isSignInPage() && !this.newComicService.isVerificationScreen();
    this.isStoryPage = this.comicService.isStoryPage();
    this.isHomePage = this.newComicService.isHomePage();

    if (!this.isSeriesPage && !this.isComicPage && !this.isStoryPage) {
      let counter = 0;
      const intervalId = setInterval(() => {
        // Update the values every second
        this.isSeriesPage = this.comicService.isSeriesPage();
        this.isComicPage = this.comicService.isComicPage() && !this.newComicService.isInfluencePointsPage() && !this.newComicService.isSubscribePage() && !this.newComicService.isSignUpNewPage() && !this.newComicService.isSignInPage() && !this.newComicService.isVerificationScreen();
        this.isStoryPage = this.comicService.isStoryPage();
        this.isHomePage = this.newComicService.isHomePage();
    
        counter++; // Increment the counter each time
    
        if (counter >= 10) { // After 10 executions
          clearInterval(intervalId); // Stop the interval
        }
      }, 1000); // Run every 1 second
    }
  }

  openLinkInNewTab(link) {
    this.comicService.viewsForExternalLink(link, 1);
    window.open(link, '_blank');
  }
}